import clsx from 'clsx';
import React, { ReactElement, useEffect, useState } from 'react';

interface Props {
    children: ReactElement
    open: boolean
    handleClose: (open: boolean) => void
    width?: 'standard' | 'medium' | 'large'
}

export function ModalComponent(props: Props) {
    const { children, open, width, handleClose } = props;
    const [isVisible, setIsVisible] = useState(open);

    useEffect(() => {
        setIsVisible(open);
    }, [open]);

    const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            handleClose(false);
        }
    };

    return (
        <>
            {isVisible && (
                <div
                    //onClick={handleBackdropClick}
                    className={"fixed flex justify-center items-center top-0 left-0 bottom-0 right-0 z-[1300] backdrop-blur-sm bg-neutral-900 bg-opacity-50"}
                >
                    <div
                        className={clsx("rounded-2xl p-5 box-border outline-none",
                            {
                                "w-[480px]": width === 'medium',
                                "w-[400px]": width === 'standard',
                                "w-[760px]": width === 'large',
                            })
                        }
                    >
                        {children}
                    </div>
                </div>
            )}
        </>
    );
}
