import clsx from 'clsx'
import React, { ReactNode, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hook.ts'
import { PromiseStatuses } from '../../../lib/utilis/index.ts'
import { TickIcon } from '../../../ui/icons/tickIcon.tsx'
import { ButtonComponent } from '../../../ui/molecules/button/index.tsx'
import { InputComponent } from '../../../ui/molecules/input/index.tsx'
import { PaymentSwitch } from '../../../ui/molecules/paymentSwitch/index.tsx'
import { SpinnerComponent } from '../../../ui/molecules/spinner/index.tsx'
import { SubscriptionChoiceCard } from '../../../ui/organisms/subscriptionChoiceCard/index.tsx'
import { SubscriptionPeriod, SubscriptionTierLimit, subscriptionTierLimitMap, SubscriptionType } from '../dto.ts'
import { findStripeProduct, setFilterFindProductPeriod, valideStripePromoCode } from '../slice.ts'
import { resetSelectPlanStripeStatus, selectPlanStripeSpontaneous, setPriceToPay, setPromoCode, setSelectPlanStripeSpontaneous, setSignUpStep, setSpontaneousFinancialAdvisorsSubscriptionTierLimit } from './slice.ts'
import { colors } from '../../../ui/colors.ts'

export function SubscriptionChoice() {
    const dispatch = useAppDispatch()
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const spontaneousState = useAppSelector(state => state.spontaneous)

    const [status, setStatus] = useState<PromiseStatuses>("loading")

    useEffect(() => {
        dispatch(findStripeProduct(financialAdvisorState.filterFindProduct))
    }, [])

    useEffect(() => {
        if (financialAdvisorState.findStripeProductStatus === "successfully") {
            setStatus("successfully")
        }
    }, [financialAdvisorState.findStripeProductStatus, status])

    useEffect(() => {
        if (spontaneousState.selectPlanStripeStatus === 'successfully') {
            if (spontaneousState.selectPlanStripeRequest.subscriptionTierLimit !== SubscriptionTierLimit.free) {
                const link = document.createElement('a')
                link.href = spontaneousState.selectPlanStripeSpontaneousResponse!.url
                link.rel = "noreferrer"
                link.target = "_blank "
                link.click()
            }
            else {
                dispatch(setSignUpStep(3))
            }
        }
    }, [spontaneousState.selectPlanStripeStatus])

    let listProduct: ReactNode[] = []

    const companiesPlanMap = new Map<String, string>(
        [
            ['free', '1'],
            ['start', '20'],
            ['professional', '50'],
            ['business', '100'],
            ['unlimited', 'infinite']
        ]
    )

    let discount = (spontaneousState.valideStripePromoCodeResponse ?
        spontaneousState.valideStripePromoCodeResponse?.discountPercentage / 100 : 0)
    const labels = [
        { label: "Annuale", value: SubscriptionType.year },
        { label: "Trimestrale", value: SubscriptionType.month },
    ];

    const formatPrice = (amount: number, type: string, name: string) => {
        let unitAmount;

        if (type === SubscriptionType.year) {
            let price = amount / 100
            let price3 = price / 12
            unitAmount = price3
        } else {
            let price = amount / 100
            let price2 = price / 3
            unitAmount = name === "Free" || name === "Start" ? price2 : price2.toFixed(1)
        }

        return unitAmount
    }

    if (financialAdvisorState.findStripeProductResponse !== undefined) {
        financialAdvisorState.findStripeProductResponse.forEach((product) => {
            listProduct.push(
                <SubscriptionChoiceCard
                    key={product.id}
                    name={product.name}
                    price={formatPrice(Number(product.unitAmount), financialAdvisorState.filterFindProduct.period, product.name)}
                    onClick={() => {
                        dispatch(setSpontaneousFinancialAdvisorsSubscriptionTierLimit(product.name.toLowerCase()))
                        dispatch(setPriceToPay(formatPrice(Number(product.unitAmount), financialAdvisorState.filterFindProduct.period, product.name)))
                        dispatch(resetSelectPlanStripeStatus())
                        dispatch(setSelectPlanStripeSpontaneous({
                            ...spontaneousState.selectPlanStripeRequest,
                            stripeProductId: product.id,
                            subscriptionTierLimit: product.name.toLowerCase() as SubscriptionTierLimit,
                            subscriptionType: product.name === 'Free' ?
                                SubscriptionType.free : financialAdvisorState.filterFindProduct.period
                        }))
                    }}
                    onChange={() => {
                        dispatch(setSpontaneousFinancialAdvisorsSubscriptionTierLimit(product.name.toLowerCase()))
                        dispatch(setPriceToPay(formatPrice(Number(product.unitAmount), financialAdvisorState.filterFindProduct.period, product.name)))
                        dispatch(resetSelectPlanStripeStatus())
                        dispatch(setSelectPlanStripeSpontaneous({
                            ...spontaneousState.selectPlanStripeRequest,
                            stripeProductId: product.id,
                            subscriptionTierLimit: product.name.toLowerCase() as SubscriptionTierLimit,
                            subscriptionType: product.name === 'Free' ?
                                SubscriptionType.free : financialAdvisorState.filterFindProduct.period
                        }))
                    }}
                    checked={product.name.toLowerCase() === spontaneousState.selectPlanStripeRequest.subscriptionTierLimit}
                    infoProduct={
                        <div key={product.id}>
                            {product.description.split(',').map(item =>
                                <div key={product.name} className='flex flex-row items-center gap-2'>
                                    <TickIcon color={colors.green[500]} size='12' />
                                    <span className=' font-bold text-text-xs lg:text-text-sm text-neutral-800'>
                                        {item}
                                    </span>
                                </div>)}
                        </div>
                    }

                    isLoading={financialAdvisorState.findStripeProductStatus === 'loading'}
                />
            )
        })
    }

    return (
        <>
            <h1 className=' font-bold text-heading-lg lg:text-heading-xl text-neutral-850'>Scegli il tuo piano</h1>
            <div className=' pb-4'>
                <PaymentSwitch
                    options={labels}
                    supportingText={financialAdvisorState.filterFindProduct.period === SubscriptionPeriod.year ? "10% di risparmio" : ""}
                    onChange={(value) => {
                        dispatch(setFilterFindProductPeriod(value))
                        dispatch(setSelectPlanStripeSpontaneous({ ...spontaneousState.selectPlanStripeRequest, subscriptionPeriod: value as SubscriptionPeriod }))
                        dispatch(findStripeProduct({
                            period: value as SubscriptionPeriod,
                            sort: true
                        }));
                    }}
                    selectedValue={financialAdvisorState.filterFindProduct.period}
                    disabled={financialAdvisorState.findStripeProductStatus === 'loading'}
                />
            </div>
            <div className=' flex flex-col items-center gap-10 w-full '>
                <div className={clsx('flex flex-row gap-4 justify-center max-w-full ')}>
                    {
                        status === "loading" ?
                            <div className='flex justify-center items-center w-full'>
                                <SpinnerComponent />
                            </div>
                            :
                            listProduct
                    }
                </div>
                {spontaneousState.spontaneousFinancialAdvisorRequest.subscriptionTierLimit !== SubscriptionTierLimit.free &&
                    <div className=' flex flex-col gap-6 items-center w-full'>
                        <div className=' w-full bg-white border border-brandPrimary-200 shadow-sm 
                           flex flex-col gap-3  items-start rounded-2xl py-4 px-6 '>
                            <div className='font-bold text-lg text-neutral-800 '>Codice sconto</div>
                            <div className='flex flex-row w-full gap-2'>
                                <InputComponent onChangeText={(text) => dispatch(setPromoCode(text))}
                                    typeOfText={spontaneousState.valideStripePromoCodeResponse?.codeFound === undefined ?
                                        "" : spontaneousState.valideStripePromoCodeResponse?.codeFound ? "success" : "error"}
                                    supportingText={spontaneousState.valideStripePromoCodeResponse?.codeFound === undefined ? "" :
                                        spontaneousState.valideStripePromoCodeResponse?.codeFound ?
                                            "Il codice sconto è stato applicato con successo!" : "Codice sconto non valido!"}
                                    placeholder='NuovoCliente2024'
                                    type='text' />
                                <div >
                                    <ButtonComponent onClick={() => {
                                        dispatch(valideStripePromoCode(spontaneousState.promoCode))
                                    }}
                                        label={'Applica'} size={'md'}
                                        iconPosition={'off'} variant={'outline'}
                                        color={'gray'} />
                                </div>
                            </div>
                        </div>
                        <div className=' w-full bg-white border border-brandPrimary-200 shadow-sm 
                        flex flex-col py-2 px-6 items-start rounded-2xl'>
                            <div className='flex flex-row justify-between w-full text-neutral-500 py-3'>
                                <span className='text-lg font-bold text-neutral-700'>
                                    Piano {subscriptionTierLimitMap.get(spontaneousState.selectPlanStripeRequest.subscriptionTierLimit) + "\t"}
                                    <span className=' font-medium text-md text-neutral-500' >
                                        (fino a {companiesPlanMap.get(spontaneousState.spontaneousFinancialAdvisorRequest.subscriptionTierLimit)} aziende)
                                    </span>
                                </span>
                                <span className='font-semibold text-sm text-neutral-600'>€
                                    {spontaneousState.priceToPay}
                                </span>
                            </div>
                            {spontaneousState.valideStripePromoCodeResponse?.codeFound && <div className='flex flex-row justify-between border-t border-dashed border-t-brandPrimary-200 
                              w-full text-neutral-500 py-3'>
                                <span className='text-text-md font-medium text-neutral-700'>
                                    Sconto (-{spontaneousState.valideStripePromoCodeResponse ?
                                        spontaneousState.valideStripePromoCodeResponse.discountPercentage : 0}%)
                                </span>
                                <span className='font-semibold text-sm text-neutral-600'>
                                    € {spontaneousState.priceToPay * discount}
                                </span>
                            </div>}
                            <div className='flex flex-row border-t-brandPrimary-200 border-t border-dashed w-full justify-between py-4'>
                                <div className='flex flex-col '>
                                    <span className='font-bold text-text-lg text-neutral-800'>Totale</span>
                                </div>
                                <span className='font-bold text-heading-sm text-neutral-800'>
                                    € {spontaneousState.priceToPay - spontaneousState.priceToPay * discount}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                {
                    spontaneousState.selectPlanStripeStatus === 'idle' &&
                    <ButtonComponent label={spontaneousState.spontaneousFinancialAdvisorRequest.subscriptionTierLimit === SubscriptionTierLimit.free ?
                        'Crea il tuo account' : 'Procedi al pagamento'} size={'lg'}
                        iconPosition={'off'} variant={'solid'} color={'blue'}
                        onClick={() => {
                            dispatch(selectPlanStripeSpontaneous({
                                id: spontaneousState.id,
                                request: spontaneousState.selectPlanStripeRequest
                            }))
                        }
                        }
                    />
                }
                {
                    spontaneousState.selectPlanStripeStatus === 'loading' &&
                    <SpinnerComponent />
                }
                <div className='text-neutral-500 text-center flex flex-col justify-center 
                   items-center text-text-xs font-medium '>
                    <span>E'possibile controllare la data di rinnovo o annullare  </span>
                    <div className=' flex flex-row items-center '>
                        <span>in qualsiasi momento.</span>
                    </div>
                </div>
            </div>
        </>
    )
}
