import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Register } from "../../pages/financialAdvisor/signup/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { SuccesfulSignup } from "../../pages/financialAdvisor/signup/succesfulSignup.tsx";


export const unregisteredRouter = createBrowserRouter([
    {
        path: '/reg',
        element:
            <Register />

    },
    {
        path: '/success',
        element:
            <SuccesfulSignup />

    },
    {
        path: '*',
        element: <LoadSpinner />
    },
],)

function LoadSpinner() {
    return (
        <div className={"w-full h-[100vh] flex justify-center items-center"}>
            <SpinnerComponent />
        </div>
    )
}

