import clsx from "clsx";
import React, { ReactElement, ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { colors } from "../colors.ts";
import { Breadcrumb } from "../organisms/breadcrumb/index.tsx";
import { HeaderComponent } from "../organisms/header/index.tsx";
import { HeadingSize } from "../organisms/heading/dto.ts";
import { HeadingComponent } from "../organisms/heading/index.tsx";
import { MenuItems } from "../organisms/sidebar/dto.ts";
import SidebarComponent from "../organisms/sidebar/index.tsx";
import { MenuStatus } from "./dto.ts";
import { setMenuStatus } from "./slice.ts";

interface Props {
    menuItem: MenuItems
    children: ReactNode
    headingLabel: string | ReactElement
    headingSubLabel?: string
    headingButtons: ReactElement[]
    breadcrumbItems: string[]
    breadcrumbIcon: ReactElement
    showSpinner?: boolean
}

export function LayoutComponent(props: Props) {
    const { menuItem } = props
    const { children } = props
    const { headingLabel } = props
    const { headingSubLabel } = props
    const { headingButtons } = props
    const { breadcrumbItems } = props
    const { breadcrumbIcon } = props
    const { showSpinner } = props

    const layoutState = useAppSelector(state => state.layout)
    const dispatch = useAppDispatch()

    return (
        <div
            style={{
                backgroundColor: colors.neutral[100],
                maxHeight: "100vh",
                display: 'flex'
            }}
            className={`antialiased min-h-screen min-w-fit bg-white`}
        >
            <div
                onMouseEnter={() => {
                    if (layoutState.menuStatus === MenuStatus.CLOSED) {
                        dispatch(setMenuStatus(MenuStatus.HOVER))
                    }
                }}
                onMouseLeave={() => {
                    if (layoutState.menuStatus === MenuStatus.HOVER) {
                        dispatch(setMenuStatus(MenuStatus.CLOSED))
                    }
                }}
                className={
                    clsx(
                        "transition-all hidden lg:block z-50",
                        {
                            "w-[240px]": layoutState.menuStatus === MenuStatus.OPEN || layoutState.menuStatus === MenuStatus.HOVER,
                            "w-[72px]": layoutState.menuStatus === MenuStatus.CLOSED,
                            "absolute h-[100%] bg-white": layoutState.menuStatus === MenuStatus.HOVER
                        }
                    )
                }>
                <SidebarComponent menuItem={menuItem} />
            </div>
            <div
                className={
                    clsx(
                        "border-neutral-200 border-r-[1px] transition-all lg:hidden"
                    )
                }>
                <div
                    className="w-[100%] h-[100%] backdrop-blur-sm transition-all"
                    onClick={() => {
                        dispatch(setMenuStatus(MenuStatus.CLOSED))
                    }}
                >
                    <div
                        className="w-[240px] h-[100%] bg-white opacity-100 transition-all"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >
                        <SidebarComponent menuItem={menuItem} />
                    </div>
                </div>
            </div>
            <div
                style={{
                    flexGrow: 1, display: 'flex', flexDirection: 'column',overflow: 'auto'
                }}
                className={
                    clsx(
                        "transition-all",
                        {
                            'ml-[0px] lg:ml-[72px]': layoutState.menuStatus === MenuStatus.HOVER,
                            'ml-[0px]': layoutState.menuStatus !== MenuStatus.HOVER,
                        }
                    )
                }
            >
                <div
                    style={{ backgroundColor: colors.neutral[100], padding: "0px 24px 24px 24px", flexGrow: 1 }}
                >
                    <div
                        style={{ minHeight: '68px', backgroundColor: colors.neutral[100], display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}
                    >
                        <div>
                            <Breadcrumb items={breadcrumbItems} icon={breadcrumbIcon} />
                        </div>
                        <div>
                            <HeaderComponent />
                        </div>
                    </div>
                    <div
                        style={{
                            padding: '24px',
                            backgroundColor: colors.white,
                            borderRadius: '24px',
                            boxShadow: 'rgb(34 36 47 / 5%) 0px 1px 10px',
                            marginLeft: 0,
                            minHeight: 'calc(100vh - 98px)',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 12,
                        }}
                    >
                        <HeadingComponent
                            label={headingLabel}
                            buttons={headingButtons}
                            subLabel={headingSubLabel}
                            size={HeadingSize.LG}
                        />
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}