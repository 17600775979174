
import React, { useEffect, useState } from 'react'
import { keycloak } from '../../lib/keycloak/index.ts'
import { useAppDispatch, useAppSelector } from '../../lib/redux/hook.ts'
import { colors } from '../../ui/colors.ts'
import { CloseIcon } from '../../ui/icons/closeIcon.tsx'
import { ButtonComponent } from '../../ui/molecules/button/index.tsx'
import { InputComponent } from '../../ui/molecules/input/index.tsx'
import MultiSelectCheckbox from '../../ui/molecules/multiSelectCheckbox/index.tsx'
import { SelectComponent } from '../../ui/molecules/select/index.tsx'
import { SpinnerComponent } from '../../ui/molecules/spinner/index.tsx'
import { ModalComponent } from '../../ui/organisms/modal/index.tsx'
import { NoticeStatus, NoticeType } from './dto.ts'
import { createNotice, editNotice, resetNoticeCreationRequest, setEditNoticeMessage, setEditNoticeProminent, setEditNoticeRequestStatus, setEditNoticeSubjectLine, setEditNoticeType, setEditNoticeUserIds, setFindByIdNoticeStatus, setNoticeCreateMessage, setNoticeCreateProminent, setNoticeCreateSubjectLine, setNoticeCreateType, setNoticeCreateUserIds, setNoticeFilterReceiver, setNoticeFilterStatus, setOpenShowNoticeModal, setSaveOrEditNotice, setSelectedTab } from './slice.ts'
import { Switch } from '../../ui/molecules/switch/index.tsx'

export function NewOrEditNoticeModal() {

    const noticeState = useAppSelector(state => state.notice)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const collaboratorState = useAppSelector(state => state.collaborator)
    const companyState = useAppSelector(state => state.company)
    const referrerState = useAppSelector(state => state.referrers)
    const authState = useAppSelector(state => state.auth)

    const dispatch = useAppDispatch()
    const [draft, setDraft] = useState(false)

    let tempOptions: { name: string, code: string }[] = []
    const [options, setOptions] = useState<{ name: string, code: string }[]>([])

    useEffect(() => {
        dispatch(resetNoticeCreationRequest())
    }, [])

    useEffect(() => {
        if (noticeState.findByIdNoticeStatus === 'successfully' && noticeState.saveOrEditNotice === 'edit') {
            dispatch(setEditNoticeUserIds(noticeState.findByIdNoticeResponse?.userIds))
            dispatch(setEditNoticeMessage(noticeState.findByIdNoticeResponse?.message))
            dispatch(setEditNoticeSubjectLine(noticeState.findByIdNoticeResponse?.subjectLine))
            dispatch(setEditNoticeProminent(noticeState.findByIdNoticeResponse?.prominent))
            dispatch(setEditNoticeType(noticeState.findByIdNoticeResponse?.type))
            dispatch(setFindByIdNoticeStatus("idle"))
        }
    }, [noticeState.findByIdNoticeStatus, noticeState.saveOrEditNotice])

    useEffect(() => {
        if ((noticeState.createNoticeStatus === "successfully" || noticeState.editNoticeStatus === "successfully") && draft) {
            dispatch(setNoticeFilterReceiver(''))
            dispatch(setNoticeFilterStatus(NoticeStatus.DRAFT))
            dispatch(setSelectedTab(2));
        } else if ((noticeState.createNoticeStatus === "successfully" || noticeState.editNoticeStatus === "successfully") && !draft) {
            if (!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('operator'))
                dispatch(setNoticeFilterReceiver('false'))
            dispatch(setNoticeFilterStatus(NoticeStatus.SENT))
            dispatch(setSelectedTab(1))
        }
    }, [noticeState.createNoticeStatus, noticeState.editNoticeStatus])

    useEffect(() => {
        if (financialAdvisorState.findAllFinancialAdvisorStatus === "successfully" &&
            collaboratorState.findAllCollaboratorStatus === "successfully" &&
            companyState.findAllCompanyStatus === "successfully" &&
            referrerState.findAllReferrersStatus === "successfully" &&
            (keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator"))
        ) {
            let financialAdvisorOptions = financialAdvisorState.findAllFinancialAdvisorResponse?.data.map((item) => ({ name: item.commercialName, code: item.userId }))
            let collaboratorOtions = collaboratorState.findAllCollaboratorResponse?.data.map((item) => ({ name: item.name + " " + item.surname, code: item.userId }))
            let companyOptions = companyState.findAllCompanyResponse?.data.map((item) => ({ name: item.companyName, code: item.userId }))
            let referrerOptions = referrerState.findAllReferrersResponse?.data.map((item) => ({ name: item.name + " " + item.surname, code: item.userId }))
            tempOptions = [...financialAdvisorOptions!, ...collaboratorOtions!, ...companyOptions!, ...referrerOptions!];
        } else if (companyState.findAllCompanyStatus === "successfully" &&
            referrerState.findAllReferrersStatus === "successfully" &&
            collaboratorState.findAllCollaboratorStatus === "successfully" &&
            authState.findMeFinancialAdvisorStatus === "successfully" &&
            keycloak.hasRealmRole("financial_advisor") &&
            noticeState.saveOrEditNotice === 'edit' && !noticeState.draft
        ) {
            let financialAdvisorOptions = { name: authState.findMeFinancialAdvisorResponse?.commercialName!, code: authState.findMeFinancialAdvisorResponse?.userId! }
            let collaboratorOtions = collaboratorState.findAllCollaboratorResponse?.data.map((item) => ({ name: item.name + " " + item.surname, code: item.userId }))
            let companyOptions = companyState.findAllCompanyResponse?.data.map((item) => ({ name: item.companyName, code: item.userId }))
            let referrerOptions = referrerState.findAllReferrersResponse?.data.map((item) => ({ name: item.name + " " + item.surname, code: item.userId }))
            tempOptions = [...collaboratorOtions!, ...companyOptions!, ...referrerOptions!];
            tempOptions.push(financialAdvisorOptions)
        } else if (
            companyState.findAllCompanyStatus === "successfully" &&
            referrerState.findAllReferrersStatus === "successfully" &&
            (keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator")) &&
            noticeState.saveOrEditNotice === 'save'
        ) {
            let companyOptions = companyState.findAllCompanyResponse?.data.map((item) => ({ name: item.companyName, code: item.userId }))
            let referrerOptions = referrerState.findAllReferrersResponse?.data.map((item) => ({ name: item.name + " " + item.surname, code: item.userId }))
            tempOptions = [...companyOptions!, ...referrerOptions!];
        } else if (
            companyState.findAllCompanyStatus === "successfully" &&
            referrerState.findAllReferrersStatus === "successfully" &&
            (keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator")) &&
            noticeState.saveOrEditNotice === 'edit' && noticeState.draft
        ) {
            let companyOptions = companyState.findAllCompanyResponse?.data.map((item) => ({ name: item.companyName, code: item.userId }))
            let referrerOptions = referrerState.findAllReferrersResponse?.data.map((item) => ({ name: item.name + " " + item.surname, code: item.userId }))
            tempOptions = [...companyOptions!, ...referrerOptions!];
        } else if (
            companyState.findAllCompanyStatus === "successfully" &&
            referrerState.findAllReferrersStatus === "successfully" &&
            financialAdvisorState.findAllFinancialAdvisorStatus === "successfully" &&
            authState.findMeCollaboratorStatus === "successfully" &&
            keycloak.hasRealmRole("financial_advisor_collaborator") && noticeState.saveOrEditNotice === 'edit'
        ) {
            let financialAdvisorOptions = financialAdvisorState.findAllFinancialAdvisorResponse?.data.map((item) => ({ name: item.commercialName, code: item.userId }))
            let collaboratorOptions = { name: authState.findMeCollaboratorResponse?.name! + " " + authState.findMeCollaboratorResponse?.surname!, code: authState.findMeCollaboratorResponse?.userId! }
            let companyOptions = companyState.findAllCompanyResponse?.data.map((item) => ({ name: item.companyName, code: item.userId }))
            let referrerOptions = referrerState.findAllReferrersResponse?.data.map((item) => ({ name: item.name + " " + item.surname, code: item.userId }))
            tempOptions = [...financialAdvisorOptions!, ...companyOptions!, ...referrerOptions!];
            tempOptions.push(collaboratorOptions)
        } else if (
            authState.findMeCompanyStatus === "successfully" &&
            referrerState.findAllReferrersStatus === "successfully" &&
            keycloak.hasRealmRole("company")
        ) {
            let companyOptions = { name: authState.findMeCompanyResponse?.companyName!, code: authState.findMeCompanyResponse?.userId! }
            let referrerOptions = referrerState.findAllReferrersResponse?.data.map((item) => ({ name: item.name + " " + item.surname, code: item.userId }))
            tempOptions = [...referrerOptions!];
            tempOptions.push(companyOptions)
        } else if (authState.findMeReferrersStatus === "successfully" && keycloak.hasRealmRole("administrative_company")) {
            let referrerOptions = { name: authState.findMeReferrersResponse?.name! + " " + authState.findMeReferrersResponse?.surname!, code: authState.findMeReferrersResponse?.userId! }
            tempOptions.push(referrerOptions)
        }
        setOptions(tempOptions)
    }, [
        financialAdvisorState, collaboratorState, companyState, referrerState, authState, noticeState
    ])

    if (noticeState.findByIdNoticeStatus === 'loading')
        return
    <div className=' w-full items-center flex'><SpinnerComponent /></div>

    return (
        <ModalComponent
            width="large"
            children={
                <div className=" p-3 bg-white rounded-2xl">
                    <div className=" flex flex-col gap-4 p-4">
                        <div className=" flex justify-between items-center">
                            <span className=" text-heading-xs font-bold text-neutral-850">Nuovo avviso</span>
                            <div className=" cursor-pointer">
                                <CloseIcon
                                    size={"20"}
                                    color={colors.neutral[400]}
                                    onClick={() => {
                                        dispatch(setOpenShowNoticeModal(false)); dispatch(setSaveOrEditNotice('')); dispatch(setEditNoticeRequestStatus(""))
                                    }} />
                            </div>
                        </div>
                        <MultiSelectCheckbox
                            onChange={(e) => {
                                if (noticeState.saveOrEditNotice === 'save')
                                    dispatch(setNoticeCreateUserIds(e.value.map((item) => item.code)))
                                else
                                    dispatch(setEditNoticeUserIds(e.value.map((item) => item.code)))
                            }}
                            value={noticeState.saveOrEditNotice === 'save' ? options.filter(opt => noticeState.createNotice.userIds.includes(opt.code)) : options.filter(opt => noticeState.editNoticeRequest.userIds.includes(opt.code))}
                            option={options}
                            placeholder="Destinatari"
                            disabled={noticeState.editNoticeRequest.status === NoticeStatus.SENT ||
                                (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                                (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                            }
                        />
                        <SelectComponent
                            label='Tipo di avviso'
                            placeholder={"Tipo di avviso"}
                            options={[
                                { value: NoticeType.NEWS, label: "News", },
                                { value: NoticeType.DEADLINE, label: "Scadenze", },
                                { value: NoticeType.UPDATE, label: "Aggiornamenti", },
                                { value: NoticeType.REQUEST, label: "Richieste", },
                                { value: NoticeType.EVENT, label: "Eventi", }
                            ]}
                            value={noticeState.saveOrEditNotice === 'save' ? noticeState.createNotice.type : noticeState.editNoticeRequest.type}
                            onChange={(e) => {
                                if (noticeState.saveOrEditNotice === 'save')
                                    dispatch(setNoticeCreateType(e))
                                else
                                    dispatch(setEditNoticeType(e))
                            }}
                            disabled={noticeState.editNoticeRequest.status === NoticeStatus.SENT || (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                                (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                        />
                        <InputComponent disabled={noticeState.editNoticeRequest.status === NoticeStatus.SENT || (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                            (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                            (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                            (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))} defaultValue={noticeState.saveOrEditNotice === 'edit' ? noticeState.findByIdNoticeResponse?.subjectLine : ''} placeholder="Oggetto dell'avviso" label="Oggetto" type={"text"} onChangeText={(text) => {
                                if (noticeState.saveOrEditNotice === 'save')
                                    dispatch(setNoticeCreateSubjectLine(text))
                                else dispatch(setEditNoticeSubjectLine(text))
                            }} />
                        <InputComponent disabled={noticeState.editNoticeRequest.status === NoticeStatus.SENT || (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                            (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                            (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                            (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))} multiple
                            defaultValue={noticeState.saveOrEditNotice === 'edit' ? noticeState.findByIdNoticeResponse?.message : ''} placeholder="Messaggio dell'avviso" label="Messaggio" type={"text"} onChangeText={(text) => {
                                if (noticeState.saveOrEditNotice === 'save')
                                    dispatch(setNoticeCreateMessage(text))
                                else if (noticeState.saveOrEditNotice === 'edit') dispatch(setEditNoticeMessage(text))
                            }
                            } />
                        <Switch
                            checked={noticeState.saveOrEditNotice === 'save' ? noticeState.createNotice.prominent : noticeState.editNoticeRequest.prominent}
                            label="Segna come importante!"
                            onChange={(e) => {
                                if (noticeState.saveOrEditNotice === 'save')
                                    dispatch(setNoticeCreateProminent(e))
                                else
                                    dispatch(setEditNoticeProminent(e))
                            }}
                            disabled={noticeState.editNoticeRequest.status === NoticeStatus.SENT || (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                                (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                        />
                        <div className=" flex w-full gap-2">
                            <ButtonComponent onClick={() => {
                                setDraft(true)
                                if (noticeState.saveOrEditNotice === 'save') {
                                    dispatch(createNotice({
                                        type: noticeState.createNotice.type,
                                        status: NoticeStatus.DRAFT,
                                        userIds: noticeState.createNotice.userIds,
                                        subjectLine: noticeState.createNotice.subjectLine,
                                        message: noticeState.createNotice.message,
                                        prominent: noticeState.createNotice.prominent
                                    }))
                                    dispatch(setNoticeCreateUserIds([]))
                                    dispatch(setNoticeCreateType(''))
                                } else {
                                    dispatch(editNotice(
                                        {
                                            id: noticeState.findByIdNoticeResponse?.id.toString() ?? '',
                                            request: {
                                                type: noticeState.editNoticeRequest.type,
                                                status: NoticeStatus.DRAFT,
                                                subjectLine: noticeState.editNoticeRequest.subjectLine,
                                                message: noticeState.editNoticeRequest.message,
                                                prominent: noticeState.editNoticeRequest.prominent,
                                                userIds: noticeState.editNoticeRequest.userIds
                                            }
                                        }
                                    ))
                                }
                                dispatch(setOpenShowNoticeModal(false))
                            }} disabled={
                                noticeState.editNoticeRequest.status === NoticeStatus.SENT ||
                                (noticeState.saveOrEditNotice === 'save' &&
                                    (noticeState.createNotice.userIds.length === 0 ||
                                        noticeState.createNotice.subjectLine === '' ||
                                        noticeState.createNotice.message === '' ||
                                        noticeState.createNotice.type === '')
                                ) || (noticeState.saveOrEditNotice === 'edit' &&
                                    (noticeState.editNoticeRequest.userIds?.length === 0 ||
                                        noticeState.editNoticeRequest.subjectLine === '' ||
                                        noticeState.editNoticeRequest.message === '' ||
                                        noticeState.editNoticeRequest.type === '')
                                ) || (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                                (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                            } fullWidth label={noticeState.saveOrEditNotice === 'edit' && noticeState.editNoticeRequest.status === NoticeStatus.DRAFT ? "Modifica bozza" : "Salva come bozza"} size={"md"} iconPosition={"left"} variant={"outline"} color={"blue"} />
                            <ButtonComponent disabled={
                                noticeState.editNoticeRequest.status === NoticeStatus.SENT ||
                                (noticeState.saveOrEditNotice === 'save' &&
                                    (noticeState.createNotice.userIds.length === 0 ||
                                        noticeState.createNotice.subjectLine === '' ||
                                        noticeState.createNotice.message === '' ||
                                        noticeState.createNotice.type === '')
                                ) || (noticeState.saveOrEditNotice === 'edit' &&
                                    (noticeState.editNoticeRequest.userIds?.length === 0 ||
                                        noticeState.editNoticeRequest.subjectLine === '' ||
                                        noticeState.editNoticeRequest.message === '' ||
                                        noticeState.editNoticeRequest.type === '')
                                ) || (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                                (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                            } onClick={() => {
                                setDraft(false)
                                if (noticeState.saveOrEditNotice === 'save') {
                                    dispatch(createNotice({
                                        type: noticeState.createNotice.type,
                                        status: NoticeStatus.SENT,
                                        userIds: noticeState.createNotice.userIds,
                                        subjectLine: noticeState.createNotice.subjectLine,
                                        message: noticeState.createNotice.message,
                                        prominent: noticeState.createNotice.prominent
                                    }))
                                    dispatch(setNoticeCreateUserIds([]))
                                    dispatch(setNoticeCreateType(''))
                                } else {
                                    dispatch(editNotice(
                                        {
                                            id: noticeState.findByIdNoticeResponse?.id.toString() ?? '',
                                            request: {
                                                type: noticeState.editNoticeRequest.type,
                                                status: NoticeStatus.SENT,
                                                subjectLine: noticeState.editNoticeRequest.subjectLine,
                                                message: noticeState.editNoticeRequest.message,
                                                prominent: noticeState.editNoticeRequest.prominent,
                                                userIds: noticeState.editNoticeRequest.userIds
                                            }
                                        }
                                    ))
                                }
                                dispatch(setOpenShowNoticeModal(false))
                            }}
                                fullWidth label="Invia avviso" size={"md"} iconPosition={"left"} variant={"solid"} color={"blue"} />
                        </div>
                    </div>
                </div>
            }
            open={noticeState.openShowNoticeModal}
            handleClose={() => dispatch(setOpenShowNoticeModal(false))} />
    )
}
