import React, { useEffect, useRef, useState } from "react";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { findAvatarByObjectId, setFindDocumentStatus } from "../../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { colors } from "../../colors.ts";
import { LogoutIcon } from "../../icons/logoutIcon.tsx";
import { NotificationIcon } from "../../icons/notificationIcon.tsx";
import { SupportIcon } from "../../icons/supportIcon.tsx";
import Avatar from "../../molecules/avatar/index.tsx";
import { SpinnerComponent } from "../../molecules/spinner/index.tsx";

export function HeaderComponent() {
    const menuRef = useRef<HTMLTableCellElement>(null);

    const authState = useAppSelector(state => state.auth)
    const objectState = useAppSelector(state => state.object)
    const layuotState = useAppSelector(state => state.layout)
    const dispatch = useAppDispatch()

    const [showMenu, setShowMenu] = useState(false)
    const [altTextInitials, setAltTextInitials] = useState<string>("")
    const [name, setName] = useState<string>("")
    const [avatarToBase64, setAvatarToBase64] = useState("")
    const [isHover, setIsHover] = useState(false)

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const closeMenu = () => {
        setShowMenu(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            closeMenu();
        }
    };

    useEffect(() => {
        if (showMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);

    useEffect(() => {
        switch (true) {
            case keycloak.hasRealmRole("financial_advisor"):
                dispatch(findAvatarByObjectId(authState.findMeFinancialAdvisorResponse?.avatarObjectId!))
                setName(authState.findMeFinancialAdvisorResponse?.name + " " + authState.findMeFinancialAdvisorResponse?.surname)
                setAltTextInitials((authState.findMeFinancialAdvisorResponse?.name[0] || '') + (authState.findMeFinancialAdvisorResponse?.surname[0] || ''))
                break;
            case keycloak.hasRealmRole("operator"):
                dispatch(findAvatarByObjectId(authState.findMeOperatorResponse?.avatarObjectId!))
                setName(authState.findMeOperatorResponse?.name + " " + authState.findMeOperatorResponse?.surname)
                setAltTextInitials((authState.findMeOperatorResponse?.name[0] || '') + (authState.findMeOperatorResponse?.surname[0] || ''))
                break;
            case keycloak.hasRealmRole("financial_advisor_collaborator"):
                dispatch(findAvatarByObjectId(authState.findMeCollaboratorResponse?.avatarObjectId!))
                setName(authState.findMeCollaboratorResponse?.name + " " + authState.findMeCollaboratorResponse?.surname)
                setAltTextInitials((authState.findMeCollaboratorResponse?.name[0] || '') + (authState.findMeCollaboratorResponse?.surname[0] || ''))
                break;
            case keycloak.hasRealmRole("administrative_company"):
                dispatch(findAvatarByObjectId(authState.findMeReferrersResponse?.avatarObjectId!))
                setName(authState.findMeReferrersResponse?.name + " " + authState.findMeReferrersResponse?.surname)
                setAltTextInitials((authState.findMeReferrersResponse?.name[0] || '') + (authState.findMeReferrersResponse?.surname[0] || ''))
                break;
            case keycloak.hasRealmRole("company"):
                dispatch(findAvatarByObjectId(authState.findMeCompanyResponse?.avatarObjectId!))
                setName(authState.findMeCompanyResponse?.name + " " + authState.findMeCompanyResponse?.surname)
                setAltTextInitials((authState.findMeCompanyResponse?.name[0] || '') + (authState.findMeCompanyResponse?.surname[0] || ''))
                break;
            case keycloak.hasRealmRole('admin'):
                setAltTextInitials('AD')
                break;
            default:
                break;
        }
    }, [keycloak.hasRealmRole]);

    useEffect(() => {
        if (objectState.findAvatarByObjectIdStatus === "successfully") {
            setAvatarToBase64(objectState.avatarResponse!);
            dispatch(setFindDocumentStatus('idle'));
        }
    }, [objectState.findAvatarByObjectIdStatus]);

    if (objectState.findAvatarByObjectIdStatus === "loading") {
        return (
            <div className={"flex items-center"}>
                <SpinnerComponent size={30} />
            </div>
        )
    }

    return (
        <div className="flex flex-row gap-[16px] items-center">
            <div className="flex flex-row gap-[16px]">
                <SupportIcon />
                <NotificationIcon />
            </div>
            <div  className="cursor-pointer relative flex items-center gap-1.5"
                    onClick={e => { toggleMenu() }}
                    ref={menuRef}>
                <span className="text-text-sm font-semibold text-neutral-800">
                    {keycloak.hasRealmRole('admin') ? 'Amministratore' : name}
                </span>
                <div
                   
                >
                    <Avatar type="single" size="md" shape="circle" imageUrl={avatarToBase64} altTextInitials={altTextInitials} />
                    {
                        showMenu &&
                        <div
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                keycloak.logout();
                            }}
                            onMouseEnter={() => setIsHover(true)}
                            onMouseLeave={() => setIsHover(false)}
                            style={{
                                backgroundColor: isHover ? layuotState.theme[100] : colors.white
                            }}
                            className={"absolute right-2 shadow-sm rounded-xl p-3 gap-2 flex items-center w-[200px]"}
                        >
                            <LogoutIcon color={!isHover ? colors.neutral[500] : layuotState.theme[500]} />
                            <span
                                onMouseEnter={() => setIsHover(true)}
                                onMouseLeave={() => setIsHover(false)}
                                style={{
                                    color: isHover ? layuotState.theme[500] : colors.neutral[600]
                                }}
                                className={"text-text-md font-semibold"}
                            >
                                Esci
                            </span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}