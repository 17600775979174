import clsx from "clsx";
import { compareDesc, format, isValid } from "date-fns";
import { it } from "date-fns/locale/it";
import React, { ReactNode, useEffect, useState } from "react";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { NewObjectService } from "../../../lib/object/service.ts";
import { findFileById, setFindDocumentStatus } from "../../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { capitalize } from "../../../lib/utilis/index.ts";
import { colors } from "../../../ui/colors.ts";
import { CalendarIcon } from "../../../ui/icons/calendarIcon.tsx";
import { CheckIcon } from "../../../ui/icons/checkIcon.tsx";
import { ClockIcon } from "../../../ui/icons/clockIcon.tsx";
import { CloseIcon } from "../../../ui/icons/closeIcon.tsx";
import { DownloadIcon } from "../../../ui/icons/downloadIcon.tsx";
import { EditIcon } from "../../../ui/icons/editIcon.tsx";
import { ResolveIcon } from "../../../ui/icons/resolveIcon.tsx";
import { TrashIcon } from "../../../ui/icons/trashIcon.tsx";
import { LayoutComponent } from "../../../ui/layout/index.tsx";
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx";
import { Pills } from "../../../ui/molecules/pills/index.tsx";
import { SpinnerComponent } from "../../../ui/molecules/spinner/index.tsx";
import { ConfirmModal } from "../../../ui/organisms/confirmModal/index.tsx";
import Datepicker from "../../../ui/organisms/datepicker/Datepicker.tsx";
import { Dropdown } from "../../../ui/organisms/dropDown/index.tsx";
import { EmptyList } from "../../../ui/organisms/emptyList/index.tsx";
import { ErrorPopup } from "../../../ui/organisms/errorPopup/index.tsx";
import { HeadingSize } from "../../../ui/organisms/heading/dto.ts";
import { HeadingComponent } from "../../../ui/organisms/heading/index.tsx";
import { MenuItems } from "../../../ui/organisms/sidebar/dto.ts";
import { SuccessPopup } from "../../../ui/organisms/successPopup/index.tsx";
import { DocumentF24Status, statusF24Map } from "../documents/dto.ts";
import { UpdateStatusF24Modal } from "../documents/f24/updateStatusF24Modal.tsx";
import { findByIdDocument, setDocumentId, setFindByIdDocumentStatus, setUpdateStatusF24Modal, updateStatusF24 } from "../documents/slice.ts";
import { CompanyStatus } from "../dto.ts";
import { EventDTO, EventStatus, EventType } from "./dto.ts";
import { NewEventModal } from "./newEventModal.tsx";
import { deleteEvent, findAllEvents, setCreateEventStatus, setDeleteEventStatus, setEditEventDate, setEditEventId, setEditEventName, setEditEventNote, setEditEventStatus, setModalEventType, setNewEventDate, setOpenConfirmOrDenyModal, setOpenNewEventsModal, updateEventStatus } from "./slice.ts";
import { useNavigate } from "react-router-dom";

export function Events() {
    const eventsState = useAppSelector(state => state.events)
    const companyState = useAppSelector(state => state.company)
    const layoutState = useAppSelector(state => state.layout)
    const documentState = useAppSelector(state => state.document)
    const authState = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()

    const objectService = NewObjectService()

    const navigate = useNavigate()

    const [isDelete, setIsDelete] = useState<string | undefined>()

    let listedEvents: ReactNode[] = []
    let eventsByDaysList: { eventsByDay: { events: EventDTO[], key: Date }[] } = { eventsByDay: [] }

    useEffect(() => {
        if (authState.findMeCompanyResponse !== undefined || companyState.findByIdCompanyResponse && eventsState.eventsFilters.companyId !== 0)
            dispatch(findAllEvents(eventsState.eventsFilters))
    }, [authState.findMeCompanyResponse, companyState.findByIdCompanyResponse])

    useEffect(() => {
        if(eventsState.eventsFilters.companyId !== 0)
        dispatch(findAllEvents(eventsState.eventsFilters))
    }, [eventsState.eventsFilters.fromDate])

    useEffect(() => {
        if (eventsState.eventCreationStatus === "successfully") {
            dispatch(findAllEvents(eventsState.eventsFilters))
        }
    }, [eventsState.eventCreationStatus])

    useEffect(() => {
        if (eventsState.editEventStatus === "successfully") {
            dispatch(findAllEvents(eventsState.eventsFilters))
        }
    }, [eventsState.editEventStatus])

    useEffect(() => {
        if (eventsState.deleteEventStatus === "successfully") {
            dispatch(findAllEvents(eventsState.eventsFilters))
        }
    }, [eventsState.deleteEventStatus])

    useEffect(() => {
        if (eventsState.updateEventStatusStatus === "successfully") {
            dispatch(findAllEvents(eventsState.eventsFilters))
        }
    }, [eventsState.updateEventStatusStatus])

    useEffect(() => {
        if (documentState.updateStatusF24Status === "successfully") {
            dispatch(findAllEvents(eventsState.eventsFilters))
        }
    }, [documentState.updateStatusF24Status])

    let body: ReactNode[] = []

    body.push(
        <>
            <SuccessPopup
                active={eventsState.eventCreationStatus === 'successfully'}
                close={() => dispatch(setCreateEventStatus('idle'))}
                message="Evento aggiunto"
            />
            <ErrorPopup
                active={eventsState.eventCreationStatus === 'failed'}
                close={() => dispatch(setCreateEventStatus('idle'))}
                message="Si è verificato un errore durante la creazione dell'evento"
            />
            <SuccessPopup
                active={eventsState.editEventStatus === 'successfully'}
                close={() => dispatch(setEditEventStatus('idle'))}
                message="Evento modificato"
            />
            <ErrorPopup
                active={eventsState.editEventStatus === 'failed'}
                close={() => dispatch(setEditEventStatus('idle'))}
                message="Si è verificato un errore durante la modifica dell'evento"
            />
            <SuccessPopup
                active={eventsState.deleteEventStatus === 'successfully'}
                close={() => dispatch(setDeleteEventStatus('idle'))}
                message="Evento eliminato"
            />
            <ErrorPopup
                active={eventsState.deleteEventStatus === 'failed'}
                close={() => dispatch(setDeleteEventStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione dell'evento"
            />
            <div className={"flex flex-row gap-6 flex-grow"}>
                <div className={"flex basis-[40%]"}>
                    {
                        eventsState.findAllEventsStatus === 'loading' ||
                            eventsState.eventCreationStatus === 'loading' ||
                            eventsState.deleteEventStatus === 'loading' ||
                            eventsState.editEventStatus === 'loading' ||
                            eventsState.updateEventStatusStatus === 'loading' ?
                            (
                                <div className={"flex justify-center items-center flex-grow"}>
                                    <SpinnerComponent />
                                </div>
                            ) : (
                                eventsState.findAllEventsResponse?.total === 0 ?
                                    (
                                        <div className={"flex justify-center items-center flex-grow"}>
                                            <EmptyList />
                                        </div>
                                    ) :
                                    (
                                        <div
                                            className={clsx("flex justify-start items-start flex-grow flex-col gap-2 overflow-auto p-r-[10px]", {
                                                "max-h-[65vh]": companyState.selectedOperationTab === 4,
                                                "max-h-[45vh]": companyState.selectedOperationTab === 0
                                            })}
                                        >
                                            {listedEvents}
                                        </div>
                                    )
                            )
                    }
                </div>
                <div className=" basis-[60%]">
                    <Datepicker
                        onClick={(date) => {
                            if (isValid(new Date(date)))
                                dispatch(setNewEventDate(format(new Date(date), 'yyyy-MM-dd')))
                            else {
                                dispatch(setNewEventDate(format(new Date(), 'yyyy-MM-dd')))
                            }
                            dispatch(setModalEventType('save'))
                            dispatch(setOpenNewEventsModal(true))
                        }}
                    />
                </div>
            </div>
            <NewEventModal />
            <UpdateStatusF24Modal />
            <ConfirmModal
                open={eventsState.openConfirmOrDenyModal}
                handleClose={() => dispatch(setOpenConfirmOrDenyModal(false))}
                title={"Elimina evento"}
                description={"Sei sicuro di voler eliminare questo evento?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    isDelete && dispatch(deleteEvent(isDelete))
                    dispatch(setOpenConfirmOrDenyModal(false))
                }}
            />
        </>
    )

    if (
        eventsState.findAllEventsStatus === 'successfully' &&
        eventsState.findAllEventsResponse !== undefined &&
        eventsState.findAllEventsResponse.data !== undefined
    ) {
        eventsState.findAllEventsResponse.data.slice().sort((a, b) => new Date(a.date as unknown as string).getTime() - new Date(b.date as unknown as string).getTime()).forEach(event => {
            let eventByDay = eventsByDaysList.eventsByDay.find(listedEvent => format(new Date(event.date), 'yyyy-MM-ddd') === format(new Date(listedEvent.key), 'yyyy-MM-ddd'))
            if (eventByDay === undefined) {
                eventsByDaysList.eventsByDay.push({
                    key: event.date,
                    events: [event]
                })
            } else {
                eventByDay.events.push(event)
            }
        })
        eventsByDaysList.eventsByDay.forEach(eventByDay => {
            listedEvents.push(
                <div
                    onClick={() => { if (window.location.pathname === '/') navigate('/calendar') }}
                    key={eventByDay.key.toString()}
                    className={"cursor-pointer flex flex-row items-start px-1 py-3 border-[0.5px] border-neutral-300 w-full rounded-xl relative"}
                >
                    <div className={"w-[56px] px-0 py-1 flex flex-col gap-[2px]"}>
                        <span
                            style={{
                                color: (format(new Date(eventByDay.key), 'EEEE') === 'Sunday' ? colors.red[500] : colors.neutral[500]),
                            }}
                            className={"text-label-xs font-medium text-center"}
                        >
                            {format(new Date(eventByDay.key), 'eee', { locale: it }).toUpperCase()}
                        </span>
                        <span
                            style={{
                                color: (format(new Date(eventByDay.key), 'EEEE') === 'Sunday' ? colors.red[500] : layoutState.theme[500]),
                            }}
                            className={"text-label-lg font-bold text-center"}
                        >
                            {format(new Date(eventByDay.key), 'dd')}
                        </span>
                    </div>
                    <div
                        className={"h-full w-[1px] bg-neutral-200"}
                    />
                    <div className={"flex flex-col items-start gap-2 w-full"}>
                        {
                            eventByDay.events.map((event, index) =>
                                <>
                                    <div
                                        key={event.id}
                                        className={"flex px-4 py-1 flex-col items-start flex-grow w-full relative"}
                                    >
                                        <div className={"flex flex-col items-start justify-start gap-1"}>
                                            <div className=" flex gap-4 ">
                                                {
                                                    event.type === EventType.F24 &&
                                                    (
                                                        <>
                                                            {
                                                                event.status === EventStatus.NotResolved &&
                                                                    (
                                                                        compareDesc(new Date(event.date), new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) !== -1 ?
                                                                            <Pills
                                                                                key={event.id}
                                                                                label={"Scaduto"}
                                                                                size={"xs"}
                                                                                color={"red"}
                                                                                emphasis={false}
                                                                                outline={false}
                                                                            />
                                                                            :
                                                                            <Pills
                                                                                key={event.id}
                                                                                label={"In attesa"}
                                                                                size={"xs"}
                                                                                color={"blue"}
                                                                                emphasis={false}
                                                                                outline={false}
                                                                            />

                                                                    )
                                                            } 
                                                            <Pills
                                                                size={"xs"}
                                                                label={statusF24Map.get(event.f24Status)!}
                                                                outline={false}
                                                                emphasis={true}
                                                                color={event.f24Status === DocumentF24Status.Idle ? "blue" : event.f24Status === DocumentF24Status.Delay ? 'red' : 'green'}
                                                            />
                                                        </>
                                                    )
                                                }
                                                {
                                                    (companyState.selectedOperationTab !== 0 || window.location.pathname === "/calendar") &&
                                                    <div className=" absolute top-[10%] right-[1%]">
                                                        <Dropdown
                                                            items={[
                                                                {
                                                                    visible: event.type === EventType.F24,
                                                                    label: 'Modifica',
                                                                    icon: <EditIcon color="" size={""} />,
                                                                    onClick: () => {
                                                                        dispatch(setModalEventType('edit'))
                                                                        dispatch(setEditEventName(event.name))
                                                                        dispatch(setEditEventDate(format(new Date(event.date), 'yyyy-MM-dd')))
                                                                        dispatch(setEditEventNote(event.note))
                                                                        dispatch(setEditEventId(event.id))
                                                                        dispatch(setOpenNewEventsModal(true))
                                                                    }
                                                                },
                                                                {
                                                                    visible: event.type !== EventType.F24,
                                                                    label: 'Scarica',
                                                                    icon: <DownloadIcon size={"24"} color={""} />,
                                                                    onClick: () => {
                                                                        dispatch(findByIdDocument(event.documentId)).then((document) => {
                                                                            //@ts-ignore
                                                                            dispatch(findFileById(document.payload.objectId)).then((e) => {
                                                                                //@ts-ignore
                                                                                objectService.downloadBase64WithExtension(document.payload.fileName, e.payload !== null ? e.payload : '')
                                                                                if (event.f24Status === DocumentF24Status.Delay && (!keycloak.hasRealmRole("company") || !keycloak.hasRealmRole("administrative_company"))) {
                                                                                    dispatch(updateStatusF24({ id: event.documentId.toString(), data: { f24Status: DocumentF24Status.Postponed } }))
                                                                                }
                                                                                dispatch(setFindDocumentStatus('idle'))
                                                                                dispatch(setFindByIdDocumentStatus('idle'))
                                                                            })
                                                                        })
                                                                    }
                                                                },
                                                               /*  {
                                                                    visible: (event.type !== EventType.F24 || companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                                                        (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                                                        (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                                                                        (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                                                        (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))),
                                                                    label: event.status === EventStatus.NotResolved ? 'Risolvi' : 'Da risolvere',
                                                                    icon:
                                                                        event.status === EventStatus.NotResolved ?
                                                                            <ResolveIcon color="" size={"24"} />
                                                                            :
                                                                            <CloseIcon color={""} size={"24"} />,
                                                                    onClick: () => dispatch(updateEventStatus({ id: event.id, data: { status: event.status === EventStatus.NotResolved ? EventStatus.Resolved : EventStatus.NotResolved } }))
                                                                }, */
                                                                {
                                                                    visible: event.type !== EventType.F24 ||
                                                                        (keycloak.hasRealmRole("financial_advisor") ||
                                                                            keycloak.hasRealmRole("financial_advisor_collaborator")
                                                                        ) ||
                                                                        event.f24Status !== DocumentF24Status.Idle ||
                                                                        companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                                                        (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                                                        (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")),
                                                                    label: "Conferma pagamento",
                                                                    icon: <CheckIcon size={"24"} color={""} />,
                                                                    onClick: () => dispatch(updateStatusF24({ id: event.documentId, data: { f24Status: DocumentF24Status.Pay } }))
                                                                },
                                                                {
                                                                    visible: event.type !== EventType.F24 ||
                                                                        (keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("financial_advisor_collaborator"))
                                                                        || event.f24Status !== DocumentF24Status.Idle ||
                                                                        companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                                                        (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                                                        (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")),
                                                                    label: "Posticipa",
                                                                    icon: <ClockIcon size={"24"} color={""} />,
                                                                    onClick: () => {
                                                                        dispatch(setDocumentId(event.documentId))
                                                                        dispatch(setUpdateStatusF24Modal(true))
                                                                    }
                                                                },
                                                                {
                                                                    label: 'Cancella',
                                                                    icon: <TrashIcon color="" size={""} />,
                                                                    onClick: () => {
                                                                        setIsDelete(event.id)
                                                                        dispatch(setOpenConfirmOrDenyModal(true))
                                                                    },
                                                                    visible: companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                                                        (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                                                        (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                                                                        (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                                                        (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <span className={clsx("text-text-md font-semibold text-neutral-800")}>
                                                {event.name}
                                            </span>
                                        </div>
                                        <span className={clsx("text-text-sm font-medium text-neutral-400")}>
                                            {event.note !== null && event.note.length < 140 ? event.note : event.note?.substring(0, 140).concat("...")}</span>
                                    </div>

                                    {
                                        eventByDay.events.length - 1 !== index &&
                                        <div
                                            className={"h-[1px] w-full self-center bg-neutral-200"}
                                        />
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
            )
        })
    }

    return (
        <>
            {
                window.location.pathname === "/calendar" ?
                    <LayoutComponent
                        menuItem={MenuItems.CALENDAR}
                        headingLabel={"Calendario"}
                        headingButtons={[
                            <ButtonComponent
                                label="Aggiungi scadenza"
                                size={"sm"}
                                iconPosition={"off"}
                                variant={"solid"}
                                color={"blue"}
                                onClick={() => {
                                    dispatch(setModalEventType('save'))
                                    dispatch(setOpenNewEventsModal(true))
                                }}
                                disabled={companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                    (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                    (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                                }
                            />
                        ]}
                        breadcrumbItems={["Calendario"]}
                        breadcrumbIcon={<CalendarIcon size={"20"} color={layoutState.theme[500]} />}
                    >
                        {body}
                    </LayoutComponent>
                    :
                    <div className={"flex flex-col gap-6 w-full"}>
                        <HeadingComponent
                            label={"Prossime scadenze di " + capitalize(format(eventsState.currentMonth, 'MMMM yyyy', { locale: it }))}
                            buttons={[
                                <ButtonComponent
                                    label="Aggiungi scadenza"
                                    size={"sm"}
                                    iconPosition={"off"}
                                    variant={"solid"}
                                    color={"blue"}
                                    onClick={() => {
                                        dispatch(setModalEventType('save'))
                                        dispatch(setOpenNewEventsModal(true))
                                    }}
                                    disabled={companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                        (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                        (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                                        (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                        (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                                    }
                                />
                            ]}
                            size={HeadingSize.MD}
                        />
                        {body}
                    </div>
            }
        </>
    )
}