import clsx from "clsx";
import React from "react";

interface Props {
    isSmall? : boolean
}

export function EmptyList(props : Props) {

    return (
        <div className={'empty-list flex flex-col items-center justify-center flex-grow m-0'}>
            <div className={clsx("font-medium text-neutral-800 text-center", {
                "text-heading-sm" : !props.isSmall,
                "text-heading-xs" : props.isSmall
            })}>
                Nessun risultato trovato
            </div>
            <div className={clsx("font-medium text-neutral-400", {
                "text-heading-xs" : !props.isSmall,
                " text-text-md leading-6" : props.isSmall
            })} style={{ textAlign: 'center' }}>La tua ricerca non ha prodotto risultati.</div>
        </div>
    );
}