import React, { useEffect } from 'react';
import { keycloak } from './app/lib/keycloak/index.ts';
import { setKeycloakInitStatus, setRoles, setSocket, urlMap } from './app/lib/keycloak/slice.ts';
import { findAvatarFinancialByObjectId, setFindAvatarbyObjectIdStatus } from './app/lib/object/slice.ts';
import { useAppDispatch, useAppSelector } from './app/lib/redux/hook.ts';
import { KeycloakRouter } from './app/lib/router/index.tsx';
import { generateTheme } from './app/lib/utilis/index.ts';
import { findMeCollaborator, findMeCompany, findMeFinancialAdvisor, findMeOperator, findMeReferrer } from './app/pages/auth/slice.ts';
import { unSeenMessages } from './app/pages/company/chat/slice.ts';
import { documentReport, findAllDocuments, setDocumentFilterCompanyId } from './app/pages/company/documents/slice.ts';
import { CompanyStatus } from './app/pages/company/dto.ts';
import { setEventFilterCompanyId, setNewEventCompanyId } from './app/pages/company/events/slice.ts';
import { findByIdCompany, setFindByIdCompanyStatus } from './app/pages/company/slice.ts';
import { findByIdFinancialAdvisor, setEditPrimaryColor } from './app/pages/financialAdvisor/slice.ts';
import { NoticeRead } from './app/pages/notice/dto.ts';
import { setNoticeFilterReceiver, setNoticeFiltersRead } from './app/pages/notice/slice.ts';
import { setTheme } from './app/ui/layout/slice.ts';
import { SpinnerComponent } from './app/ui/molecules/spinner/index.tsx';

function App() {
  const dispatch = useAppDispatch()

  const keycloakState = useAppSelector(state => state.secured)
  const authState = useAppSelector(state => state.auth)
  const companyState = useAppSelector(state => state.company)
  const documentState = useAppSelector(state => state.document)
  const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
  const objectState = useAppSelector(state => state.object)

  useEffect(() => {
    if ((authState.findMeCompanyStatus === "successfully" && keycloak.hasRealmRole("company")) ||
      (authState.findMeReferrersStatus === "successfully" && keycloak.hasRealmRole("administrative_company"))) {
      if (authState.findMeCompanyResponse?.status === CompanyStatus.SUSPENDED) {
        keycloak.logout()
      }
      dispatch(setDocumentFilterCompanyId(authState.findMeReferrersResponse?.company ?? authState.findMeCompanyResponse?.id))
      dispatch(setEventFilterCompanyId(authState.findMeReferrersResponse?.company ?? authState.findMeCompanyResponse?.id))
      dispatch(setNewEventCompanyId(authState.findMeReferrersResponse?.id ?? authState.findMeCompanyResponse?.id))
      dispatch(setNoticeFilterReceiver('true'))
      dispatch(setNoticeFiltersRead(NoticeRead.toRead))
    }
  }, [authState.findMeCompanyStatus, authState.findMeReferrersStatus, keycloak.hasRealmRole])

  useEffect(() => {
    if (authState.findMeReferrersStatus === "successfully" && keycloak.hasRealmRole("administrative_company")) {
      dispatch(findByIdCompany(authState.findMeReferrersResponse?.company !== undefined ? authState.findMeReferrersResponse.company : ''))
    }
  }, [authState.findMeReferrersStatus])

  useEffect(() => {
    if ((authState.findMeCompanyStatus === "successfully" && keycloak.hasRealmRole("company")) || (keycloak.hasRealmRole("administrative_company") && companyState.findByIdCompanyStatus === "successfully")) {
      if (companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED && keycloak.hasRealmRole("administrative_company")) {
        keycloak.logout()
      }
      dispatch(setFindByIdCompanyStatus("idle"))
      let financialAdvisorId = keycloak.hasRealmRole("company") ? authState.findMeCompanyResponse?.financialAdvisor : companyState.findByIdCompanyResponse?.financialAdvisor
      dispatch(findByIdFinancialAdvisor(financialAdvisorId!))
      dispatch(unSeenMessages(authState.findMeCompanyResponse?.id ?? companyState.findByIdCompanyResponse?.id!))
    }
  }, [authState.findMeCompanyStatus, companyState.findByIdCompanyStatus])

  useEffect(() => {
    if (financialAdvisorState.findByIdFinancialAdvisorStatus === "successfully" && (keycloak.hasRealmRole('company') || keycloak.hasRealmRole("administrative_company"))) {
      dispatch(findAvatarFinancialByObjectId(financialAdvisorState.findByIdFinancialAdvisorResponse?.avatarObjectId!))
    }
  }, [financialAdvisorState.findByIdFinancialAdvisorStatus])

  useEffect(() => {
    if (objectState.findAvatarFinancialStatus === "successfully") {
      dispatch(setFindAvatarbyObjectIdStatus("idle"))
    }
  }, [objectState.findAvatarByObjectIdStatus])

  keycloak.onAuthSuccess = () => {
    dispatch(setRoles(keycloak.tokenParsed?.realm_access?.roles))
    dispatch(setSocket(new WebSocket(urlMap.get(window.location.origin) + "/chat", ["access-token", keycloak.token || ''])))
    dispatch(setKeycloakInitStatus('successfully'))
  }

  keycloak.onAuthRefreshSuccess = () => {
    keycloakState.socket?.close()
    dispatch(setSocket(new WebSocket(urlMap.get(window.location.origin) + "/chat", ["access-token", keycloak.token || ''])))
  }

  useEffect(() => {
    switch (true) {
      case keycloakState.userRoles.includes("financial_advisor"):
        dispatch(findMeFinancialAdvisor());
        break;
      case keycloakState.userRoles.includes("operator"):
        dispatch(findMeOperator());
        break;
      case keycloakState.userRoles.includes("financial_advisor_collaborator"):
        dispatch(findMeCollaborator());
        break;
      case keycloakState.userRoles.includes("administrative_company"):
        dispatch(findMeReferrer());
        break;
      case keycloakState.userRoles.includes("company"):
        dispatch(findMeCompany());
        break;
      default:
        break;
    }
  }, [keycloakState.userRoles]);

  useEffect(() => {
    if ((authState.findMeCompanyStatus === "successfully" && authState.findMeCompanyResponse?.primaryColor !== null) ||
      (authState.findMeCollaboratorStatus === "successfully" && authState.findMeCollaboratorResponse?.primaryColor !== null) ||
      (authState.findMeReferrersStatus === "successfully" && authState.findMeReferrersResponse?.primaryColor !== null) ||
      (authState.findMeFinancialAdvisorStatus === "successfully" && authState.findMeFinancialAdvisorResponse?.primaryColor !== null)) {
      const primaryColor = authState.findMeCompanyResponse?.primaryColor ?? authState.findMeCollaboratorResponse?.primaryColor ?? authState.findMeFinancialAdvisorResponse?.primaryColor ?? authState.findMeReferrersResponse?.primaryColor;
      const theme = generateTheme(primaryColor!);
      authState.findMeFinancialAdvisorResponse &&
        dispatch(setEditPrimaryColor(authState.findMeFinancialAdvisorResponse.primaryColor))
      dispatch(setTheme(theme));
    }
  }, [authState.findMeCompanyStatus, authState.findMeCollaboratorStatus, authState.findMeReferrersStatus, authState.findMeFinancialAdvisorStatus]);

  useEffect(() => {
    if (authState.findMeCompanyStatus === 'successfully' && authState.findMeCompanyResponse) {
      dispatch(unSeenMessages(authState.findMeCompanyResponse.id))
      dispatch(findAllDocuments({...documentState.documentFilters, type : '', companyId : Number(authState.findMeCompanyResponse.id)}))
      dispatch(documentReport(authState.findMeCompanyResponse.id))
    }
    if(authState.findMeReferrersStatus === 'successfully' && authState.findMeReferrersResponse){
      dispatch(findAllDocuments({...documentState.documentFilters, type : '', companyId : Number(authState.findMeReferrersResponse.company)}))
      dispatch(documentReport(authState.findMeReferrersResponse.company))
    }
  }, [authState.findMeCompanyStatus, authState.findMeReferrersStatus])

  keycloak.onAuthRefreshError = () => {
    keycloak.logout()
    keycloak.login()
  }

  keycloak.onAuthRefreshSuccess = () => {
    keycloakState.socket?.close()
    dispatch(setSocket(new WebSocket(urlMap.get(window.location.origin) + "/chat", ["access_token", keycloak.token || ''])))
  }

  if (
    authState.findMeCollaboratorStatus === "loading" ||
    authState.findMeCompanyStatus === "loading" ||
    authState.findMeFinancialAdvisorStatus === "loading" ||
    authState.findMeOperatorStatus === "loading" ||
    authState.findMeReferrersStatus === "loading"
  ) {
    return (
      <div className={"w-full h-[100vh] flex justify-center items-center"}>
        <SpinnerComponent />
      </div>
    )
  }

  return (
    <div className="App">
      <KeycloakRouter />
    </div>
  );
}

export default App;
