import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { colors } from "../../ui/colors.ts";
import { AddDocumentsIcon } from "../../ui/icons/addDocumentsIcon.tsx";
import { ChatIcon } from "../../ui/icons/chatIcon.tsx";
import { CloseIcon } from "../../ui/icons/closeIcon.tsx";
import { EditIcon } from "../../ui/icons/editIcon.tsx";
import { ResolveIcon } from "../../ui/icons/resolveIcon.tsx";
import { TrashIcon } from "../../ui/icons/trashIcon.tsx";
import { Pills } from "../../ui/molecules/pills/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { ConfirmModal } from "../../ui/organisms/confirmModal/index.tsx";
import { Dropdown } from "../../ui/organisms/dropDown/index.tsx";
import { InfoTextPopup } from "../../ui/organisms/infoTextPopup/index.tsx";
import { FinancialAdvisorDTO } from "../financialAdvisor/dto.ts";
import { findAllFinancialAdvisor } from "../financialAdvisor/slice.ts";
import { CompanyStatus, companyStatusMap } from "./dto.ts";
import { setEventFilterCompanyId } from "./events/slice.ts";
import { findAllReferrers } from "./referrers/slice.ts";
import { changeCompanyStatus, deleteCompany, findAllCompany, resetCreateCompanyRequest, setCompanyId, setOpenArchiveCompanyModal, setOpenConfirmModal, setSelectedOperationTab, setSelectedSettingTab } from "./slice.ts";
import { OtherIcon } from "../../ui/icons/otherIcon.tsx";
import { findChatsByLoggedUser } from "./chat/slice.ts";
import { findAllDocuments } from "./documents/slice.ts";
import { Badges } from "../../ui/molecules/badge/index.tsx";
import { AccountantIcon } from "../../ui/icons/accountantIcon.tsx";

export function CompanyList() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const companyState = useAppSelector(state => state.company)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const referrersState = useAppSelector(state => state.referrers)
    const authState = useAppSelector(state => state.auth)
    const chatState = useAppSelector(state => state.chat)
    const documentState = useAppSelector(state => state.document)

    const [isDelete, setIsDelete] = useState<string | undefined>()
    const [companyToArchive, setCompanyToArchive] = useState<string | undefined>(undefined)

    useEffect(() => {
        dispatch(findAllCompany(companyState.companyFilters))
        if (!keycloak.hasRealmRole("financial_advisor"))
            dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
        else { dispatch(findChatsByLoggedUser()); }
        if (keycloak.hasRealmRole('financial_advisor_collaborator'))
            dispatch(findChatsByLoggedUser())
        if (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('financial_advisor') || keycloak.hasRealmRole('financial_advisor_collaborator') || keycloak.hasRealmRole('operator'))
            dispatch(findAllDocuments({ ...documentState.documentFilters, companyId: null, type: "" }))
        dispatch(findAllReferrers(referrersState.referrersFilter))
    }, [])

    useEffect(() => {
        if (companyState.createCompanyStatus === "successfully") {
            dispatch(findAllCompany(companyState.companyFilters))
            dispatch(resetCreateCompanyRequest())
        }
    }, [companyState.createCompanyStatus])

    useEffect(() => {
        if (companyState.createCompanyStatus === "failed") {
            dispatch(resetCreateCompanyRequest())
        }
    }, [companyState.createCompanyStatus])

    useEffect(() => {
        if (companyState.editCompanyStatus === "successfully") {
            dispatch(findAllCompany(companyState.companyFilters))
        }
    }, [companyState.editCompanyStatus])

    useEffect(() => {
        if (companyState.deleteCompanyStatus === "successfully") {
            dispatch(findAllCompany(companyState.companyFilters))
        }
    }, [companyState.deleteCompanyStatus])

    useEffect(() => {
        if (companyState.changeCompanyStatusStatus === "successfully") {
            dispatch(findAllCompany(companyState.companyFilters))
        }
    }, [companyState.changeCompanyStatusStatus])

    if (companyState.findAllCompanyStatus === "loading" || companyState.createCompanyStatus === "loading" || companyState.editCompanyStatus === "loading" || companyState.deleteCompanyStatus === "loading" || companyState.changeCompanyStatusStatus === "loading") {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }

    let financialAdvisor: FinancialAdvisorDTO | undefined
    let companyRows: ReactNode[] = []

    if (companyState.findAllCompanyStatus === "successfully" ||
        (!keycloak.hasRealmRole("financial_advisor") && financialAdvisorState.findAllFinancialAdvisorStatus === "successfully")
    ) {
        companyState.findAllCompanyResponse?.data.forEach((item) => {
            if (!keycloak.hasRealmRole("financial_advisor")) {
                financialAdvisor = financialAdvisorState.findAllFinancialAdvisorResponse?.data.find(financialAdvisor => item.financialAdvisor === financialAdvisor.id)
            } else {
                financialAdvisor = authState.findMeFinancialAdvisorResponse
            }

            let referrers: string[] = []
            item.administrativeCompanies.forEach(referrer => {
                const selectedReferrer = referrersState.findAllReferrersResponse?.data.find(ref => ref.id === referrer)
                if (selectedReferrer !== undefined) {
                    referrers.push(selectedReferrer.name + ' ' + selectedReferrer.surname)
                }
            })

            let referrerLabel: string = '-'
            if (referrers.length === 1) {
                referrerLabel = referrers[0]
            } else if (referrers.length > 1) {
                referrerLabel = referrers[0] + ', +' + (referrers.length - 1)
            }

            companyRows.push(
                <tr
                    className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                    key={item.id}
                >
                    <td
                        className="py-4 px-4 text-text-sm cursor-pointer font-semibold text-neutral-850 hover:text-neutral-900 hover:font-bold"
                        onClick={() => {
                            dispatch(setCompanyId(item.id))
                            dispatch(setEventFilterCompanyId(item.id))
                            dispatch(setSelectedOperationTab(0))
                            navigate('/operation-company/' + item.id)
                        }}
                    >
                        {item.companyName}
                    </td>
                    <td className="py-4 px-2 text-text-sm font-semibold text-neutral-600">{item.vat}</td>
                    <td className="py-4 px-2 text-text-sm font-semibold text-neutral-600">{referrerLabel.length <= 20 ?
                        referrerLabel : referrerLabel.substring(0, 20).concat('...')}
                    </td>
                    <td className="py-4 px-2 text-text-sm font-semibold text-neutral-600">{financialAdvisor &&
                        financialAdvisor.commercialName}
                    </td>
                    <td className="py-4 px-2 text-text-sm font-semibold text-neutral-600">
                        <Pills size={"xs"} label={companyStatusMap.get(item.status)!} outline={false} emphasis={false} color={item.status === CompanyStatus.ACTIVE ? "green" : "orange"} />
                    </td>
                    <td className="h-[56px] py-4 px-2 flex justify-end flex-row items-center">
                        <div className="hover:bg-brandPrimary-50 cursor-pointer py-2 px-2 rounded-md">
                            <InfoTextPopup
                                label={"Documenti"}
                                children={
                                    <div className=" relative">
                                        {(keycloak.hasRealmRole('financial_advisor') || keycloak.hasRealmRole('financial_advisor_collaborator')) && documentState.findAllDocumentsResponse &&
                                            documentState.findAllDocumentsResponse.data.filter(element => element.companyId === Number(item.id) && element.companiesDownloadDate === null &&
                                                element.companiesDownloadDate === null &&
                                                (element.type !== 'accounting' || element.type === 'accounting' &&
                                                    (element.receiverGroup === 'operators' &&
                                                        element.operatorsDownloadDate === null))).length > 0
                                            && <div className=" absolute top-[-35%] right-[-25%]">
                                                <Badges size={"xs"}
                                                    label={
                                                        documentState.findAllDocumentsResponse.data.filter
                                                            (element => element.companyId === Number(item.id) &&
                                                                element.companiesDownloadDate === null &&
                                                                (element.type !== 'accounting' || element.type === 'accounting' &&
                                                                    (element.receiverGroup === 'operators' &&
                                                                        element.operatorsDownloadDate === null))).length.toString()
                                                    }
                                                    outline={false} emphasis={true} color={"red"} />
                                            </div>
                                        }
                                        {(keycloak.hasRealmRole('operator') || keycloak.hasRealmRole('admin'))
                                            && documentState.findAllDocumentsResponse &&
                                            documentState.findAllDocumentsResponse.data.filter(element => element.companyId === Number(item.id) && element.companiesDownloadDate === null).length > 0
                                            && <div className=" absolute top-[-35%] right-[-25%]">
                                                <Badges size={"xs"}
                                                    label={
                                                        documentState.findAllDocumentsResponse.data.filter
                                                            (element => element.companyId === Number(item.id) &&
                                                                element.companiesDownloadDate === null).length.toString()
                                                    }
                                                    outline={false} emphasis={true} color={"red"} />
                                            </div>
                                        }
                                        <AccountantIcon
                                            size={"24"}
                                            color={colors.brandSecondary[500]}
                                            onClick={() => {
                                                dispatch(setCompanyId(item.id))
                                                dispatch(setSelectedOperationTab(1))
                                                navigate('/operation-company/' + item.id)
                                            }}
                                        />
                                    </div>
                                }
                                position={"bottom"}
                            />
                        </div>
                        <div className="hover:bg-brandPrimary-50 cursor-pointer py-2 px-2 rounded-md">
                            <InfoTextPopup
                                label={"Messaggi"}
                                children={
                                    <div className=" relative">
                                        {(chatState.findChatByLoggedUserResponse &&
                                            chatState.findChatByLoggedUserResponse.find(element => element.users.includes(item.userId))
                                            && chatState.findChatByLoggedUserResponse.find(element => element.users.includes(item.userId))!.notSeenMessages > 0
                                        )
                                            &&
                                            <div className=" absolute top-[-35%] right-[-25%]">
                                                <Badges size={"xs"}
                                                    label={chatState.findChatByLoggedUserResponse.find(element => element.users.includes(item.userId))!.notSeenMessages.toString()}
                                                    outline={false} emphasis={true} color={"red"} />
                                            </div>
                                        }
                                        <ChatIcon
                                            size={"24"}
                                            color={colors.brandSecondary[500]}
                                            onClick={() => {
                                                dispatch(setCompanyId(item.id))
                                                dispatch(setSelectedOperationTab(2))
                                                navigate('/operation-company/' + item.id)
                                            }}
                                        />
                                    </div>
                                }
                                position={"bottom"}
                            />
                        </div>
                        <Dropdown items={[
                            {
                                label: 'Visualizza',
                                icon: <EditIcon color="" size={""} />,
                                onClick: () => {
                                    dispatch(setCompanyId(item.id))
                                    dispatch(setSelectedSettingTab(0))
                                    navigate('/operation-company/' + item.id)
                                }
                            },
                            {
                                label: item.status === CompanyStatus.ACTIVE ? "Archivia" : 'Attiva',
                                icon: item.status === CompanyStatus.SUSPENDED ?
                                    <ResolveIcon color="" size={"24"} />
                                    :
                                    <OtherIcon color={""} size={"24"} />,
                                onClick: () => {
                                    if (item.status === CompanyStatus.ACTIVE) {
                                        setCompanyToArchive(item.id)
                                        dispatch(setOpenArchiveCompanyModal(true))
                                    }
                                    else
                                        dispatch(changeCompanyStatus({ id: item.id, status: CompanyStatus.ACTIVE }))
                                },
                                visible: !keycloak.hasRealmRole("financial_advisor") && !keycloak.hasRealmRole("admin") && !keycloak.hasRealmRole("operator") || (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) || (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                            },
                            {
                                label: 'Cancella',
                                icon: <TrashIcon color="" size={""} />,
                                onClick: () => {
                                    setIsDelete(item.id)
                                    dispatch(setOpenConfirmModal(true))
                                },
                                visible: (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) || (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                            }
                        ]} />
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className="w-full flex flex-col gap-4 rounded-tr-xl rounded-tl-xl overflow-auto">
            <ConfirmModal open={companyState.openArchiveCompanyModal} handleClose={() => { setCompanyToArchive(undefined); dispatch(setOpenArchiveCompanyModal(false)) }} title={"Archiviazione azienda"} description={"Sei sicuro di voler archiviare quest'azienda?"} labelDeny={"Annulla"} labelConfirm={"Archivia"} actionConfirm={
                () => { dispatch(changeCompanyStatus({ id: companyToArchive!, status: CompanyStatus.SUSPENDED })); dispatch(setOpenArchiveCompanyModal(false)) }
            }
            />
            <table className="w-full mb-3">
                <thead className="h-[56px] bg-neutral-100 font-semibold text-neutral-500 text-text-xs">
                    <tr>
                        <th className="text-left">
                            <div className="flex py-4 px-4 gap-1">
                                RAGIONE SOCIALE
                            </div>
                        </th>
                        {
                            companyState.findAllCompanyResponse?.total !== 0 &&
                            <>
                                <th className="text-left">
                                    <div className="flex py-4 px-2  gap-1">
                                        PARTITA IVA
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-2  gap-1">
                                        REFERENTE
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-2  gap-1">
                                        COMMERCIALISTA
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 gap-1">
                                        STATO
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 gap-1" />
                                </th>
                            </>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        companyState.findAllCompanyResponse?.total === 0 ?
                            <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 w-full">
                                <td className="py-4 px-2 text-text-sm font-semibold text-neutral-800">
                                    Nessun risultato trovato!
                                </td>
                            </tr>
                            :
                            companyRows
                    }
                </tbody>
            </table>
            <ConfirmModal
                open={companyState.openConfirmModal}
                handleClose={() => dispatch(setOpenConfirmModal(false))}
                title={"Elimina azienda"}
                description={"Sei sicuro di voler eliminare questa azienda?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    isDelete && dispatch(deleteCompany(isDelete))
                    dispatch(setOpenConfirmModal(false))
                }}
            />
        </div>
    )
}