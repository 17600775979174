import clsx from 'clsx'
import { format } from 'date-fns'
import React from 'react'
import { useAppSelector } from '../../../lib/redux/hook.ts'
import { colors } from '../../../ui/colors.ts'
import { UserInfoDTO } from './dto.ts'

interface Props {
    message: string
    date: Date
    sent: boolean
    sender?: UserInfoDTO
    sameSanderAsPrevious: boolean
}
export function MessageComponent(props: Props) {
    const { message } = props
    const { date } = props
    const { sent } = props
    const { sender } = props
    const { sameSanderAsPrevious } = props

    const layoutState = useAppSelector(state => state.layout)

    const loadSenderData = (sender?: UserInfoDTO): string => {
        if (sender === undefined) {
            return 'Utente eliminato'
        }

        const name = (sender.role === 'companies' || sender.role === 'financial_advisors') && sender.businessName ? sender.businessName : (sender.name + ' ' + sender.surname)
        return name
    }

    return (
        <div
            style={{
                padding: '8px 12px 8px 12px',
                backgroundColor: (sent ? layoutState.theme[500] : colors.white),
                borderRadius: '12px',
                borderTopRightRadius: (sent ? '0': '12px'),
                borderTopLeftRadius: (sent? '12px': '0'),
                gap: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%',
                boxShadow: '0px 1px 2px 0px rgba(22, 42, 100, 0.12)'
            }}
        >
            {
                !sent && !sameSanderAsPrevious &&
                <div className='text-text-sm font-semibold text-neutral-850'>
                    {loadSenderData(sender)}
                </div>
            }
            <div
                style={{
                    gap: 8,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    width: '100%'
                }}
            >
                <span
                    className={
                        clsx("text-text-md font-medium text-brandPrimary-800",
                            {
                                "text-white": sent
                            })}
                >
                    {message}
                </span>
            </div>
            <div className='flex justify-end w-full'>
                <span className={
                    clsx(
                        'text-text-xs opacity-80 font-normal', {
                        "text-neutral-500": sender,
                        "text-white": sent
                    }
                    )}
                >
                    {format(new Date(date), 'HH:mm')}
                </span>
            </div>
        </div>
    )
}