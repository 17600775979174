import React, { useEffect } from 'react'
import { ButtonComponent } from '../../../ui/molecules/button/index.tsx'
import { keycloak } from '../../../lib/keycloak/index.ts'
import { useAppDispatch } from '../../../lib/redux/hook.ts'
import { resetSelectPlanStripeStatus, setCreateNewSpontaneousStatus, setPromoCode } from './slice.ts'
import { TickIcon } from '../../../ui/icons/tickIcon.tsx'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'

export function SuccesfulSignup() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    keycloak.redirectUri = window.location.href
    useEffect(() => {
        dispatch(resetSelectPlanStripeStatus())
        dispatch(setCreateNewSpontaneousStatus('idle'))
        dispatch(setPromoCode(''))
        
    }, [])

    return (
        <div className={clsx(' flex flex-col items-center gap-10 bg-brandPrimary-50', {
            'justify-center h-screen': window.location.pathname === '/success'
        })}>
            <h1 className='font-bold text-heading-xl w-[80%] text-center text-neutral-850 '>Operazione completata con successo!</h1>
            <h2 className='text-neutral-500 text-text-lg w-[80%]  font-medium text-center '>Per la tua prima sottoscrizione, controlla la tua email e
                imposta la password per iniziare.
            </h2>
            <div className=' bg-green-500 rounded-full flex justify-center items-center gap-2 p-4'>
                <TickIcon color='white' size='32' />
            </div>
            <h2 className='text-neutral-500 text-text-lg font-medium text-center '>Se hai rinnovato, torna alla home
                e continua a esplorare.
            </h2>
            <ButtonComponent onClick={
                () => {
                    keycloak.redirectUri = window.location.origin;
                    keycloak.authenticated ? navigate('/financial-advisor') : keycloak.login()
                }
            }
                label='Vai alla home' size={'lg'} iconPosition={'off'} variant={'solid'} color={'blue'} />
        </div>
    )
}
