
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { toBase64 } from "../../lib/utilis/index.ts";
import { colors } from "../../ui/colors.ts";
import { EmailIcon } from "../../ui/icons/emailIcon.tsx";
import Avatar from "../../ui/molecules/avatar/index.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { FileInputComponent } from "../../ui/molecules/fileInput/index.tsx";
import { InputComponent } from "../../ui/molecules/input/index.tsx";
import { ModalComponent } from "../../ui/organisms/modal/index.tsx";
import { createFinancialAdvisor, resetCreateFinancialAdvisorRequest, setCreateFinancialAdvisorsCommercialName, setCreateFinancialAdvisorsEmail, setCreateFinancialAdvisorsName, setCreateFinancialAdvisorsNote, setCreateFinancialAdvisorsPhoneNumber, setCreateFinancialAdvisorsSurname, setCreateFinancialAdvisorsVat, setOpenNewFinancialAdvisorModal } from "./slice.ts";

export function NewFinancialAdvisorModal() {
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const note = financialAdvisorState.createFinancialAdvisorRequest.note
    const dispatch = useAppDispatch()

    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    let formData = new FormData();

    useEffect(() => {
        if (financialAdvisorState.openNewFinancialAdvisorModal) {
            setFile(null)
            setAvatarToBase64('')
            dispatch(setCreateFinancialAdvisorsCommercialName(""))
            dispatch(setCreateFinancialAdvisorsVat(""))
        }
    }, [financialAdvisorState.openNewFinancialAdvisorModal])

    return (
        <ModalComponent
            width="medium"
            open={financialAdvisorState.openNewFinancialAdvisorModal}
            handleClose={() => {
                dispatch(setOpenNewFinancialAdvisorModal(false));
            }}
        >
            <div
                className=" p-5 bg-white rounded-xl flex flex-col gap-2.5 w-full "
                style={{
                    boxShadow: '0px 12px 24px 0px rgba(22, 42, 100, 0.12)',
                }}
            >
                <div className="flex items-center justify-between">
                    <span className="text-heading-xs font-semibold text-neutral-600">Aggiungi commercialista</span>
                </div>
                <div className="flex flex-col items-start" style={{ gap: '16px', width: '100%' }}>
                    <div className="flex gap-[16px] w-[100%]">
                        <Avatar type="single" shape="circle" size="md" imageUrl={avatarToBase64} altTextInitials={(financialAdvisorState.createFinancialAdvisorRequest.name[0] || 'A') + (financialAdvisorState.createFinancialAdvisorRequest.surname[0] || 'A')} />
                        <FileInputComponent
                            id={"create-financial-advisor-avatar"}
                            onChange={(e) => {
                                if (e.length) {
                                    setFile(e[0])
                                    toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data))
                                }
                            }}
                            color={"gray"}
                            iconSize="32"
                            iconBackground={colors.neutral[100]}
                            infoText={false}
                        />
                    </div>
                    <div className=" flex flex-row gap-4">
                        <InputComponent
                            label="Ragione sociale"
                            type={"text"}
                            defaultValue={financialAdvisorState.createFinancialAdvisorRequest.commercialName}
                            onChangeText={(value) => dispatch(setCreateFinancialAdvisorsCommercialName(value))}
                            required
                        />
                        <InputComponent
                            label="Partita IVA"
                            type={"text"}
                            defaultValue={financialAdvisorState.createFinancialAdvisorRequest.vat}
                            onChangeText={(value) => dispatch(setCreateFinancialAdvisorsVat(value))}
                            required
                        />
                    </div>
                    <div className=" flex flex-row gap-4">
                        <InputComponent
                            label="Nome"
                            type={"text"}
                            defaultValue={financialAdvisorState.createFinancialAdvisorRequest.name}
                            onChangeText={(value) => dispatch(setCreateFinancialAdvisorsName(value))}
                            required
                        />
                        <InputComponent
                            label="Cognome"
                            type={"text"}
                            defaultValue={financialAdvisorState.createFinancialAdvisorRequest.surname}
                            onChangeText={(value) => dispatch(setCreateFinancialAdvisorsSurname(value))}
                            required
                        />
                    </div>
                    <div className=" flex flex-row gap-4">
                        <InputComponent
                            label="Email"
                            type={"text"}
                            startIcon={<EmailIcon size={"20"} color={colors.neutral[400]} />}
                            defaultValue={financialAdvisorState.createFinancialAdvisorRequest.email}
                            onChangeText={(value) => dispatch(setCreateFinancialAdvisorsEmail(value))}
                            required
                        />
                        <InputComponent
                            label="Telefono"
                            type={"text"}
                            defaultValue={financialAdvisorState.createFinancialAdvisorRequest.phoneNumber}
                            onChangeText={(value) => dispatch(setCreateFinancialAdvisorsPhoneNumber(value))}
                        />
                    </div>
                    <InputComponent
                        type={"text"}
                        label="Descrizione"
                        placeholder="Un po' di informazioni su di te"
                        supportingText={(275 - note.length).toString() + " caratteri rimasti"}
                        multiple
                        onChangeText={(value) => dispatch(setCreateFinancialAdvisorsNote(value))}
                        defaultValue={financialAdvisorState.createFinancialAdvisorRequest.note}
                    />
                </div>
                <div className={"flex gap-3 items-center justify-center"}>
                    <ButtonComponent
                        label={"Annulla"}
                        onClick={() => {
                            dispatch(setOpenNewFinancialAdvisorModal(false))
                            dispatch(resetCreateFinancialAdvisorRequest())
                        }}
                        color={"gray"}
                        variant={"outline"}
                        size={"sm"}
                        iconPosition="off"
                        fullWidth
                    />
                    <ButtonComponent
                        label={"Aggiungi"}
                        onClick={() => {
                            if (file !== null)
                                formData.append('avatar', file)
                            formData.append('user', JSON.stringify(financialAdvisorState.createFinancialAdvisorRequest))
                            dispatch(createFinancialAdvisor(formData))
                            dispatch(setOpenNewFinancialAdvisorModal(false))
                        }}
                        color={"blue"}
                        variant={"solid"}
                        size={"sm"}
                        iconPosition="off"
                        fullWidth
                        disabled={
                            financialAdvisorState.createFinancialAdvisorRequest.name === "" ||
                            financialAdvisorState.createFinancialAdvisorRequest.surname === "" ||
                            financialAdvisorState.createFinancialAdvisorRequest.email === "" ||
                            !/^[A-z0-9\.\+_-]+@[A-z0-9\._-]+\.[A-z]{2,6}$/.test(financialAdvisorState.createFinancialAdvisorRequest.email) ||
                            (financialAdvisorState.createFinancialAdvisorRequest.vat.length < 11 ||
                                financialAdvisorState.createFinancialAdvisorRequest.vat.length > 15) ||
                            !/^\d+$/.test(financialAdvisorState.createFinancialAdvisorRequest.vat)
                        }
                    />
                </div>
            </div>
        </ModalComponent >
    )
}