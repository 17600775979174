import React, { ReactElement, useEffect } from "react";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { colors } from "../../ui/colors.ts";
import { ArrowLeftIcon } from "../../ui/icons/arrowLeftIcon.tsx";
import { NoticeIcon } from "../../ui/icons/noticeIcon.tsx";
import { LayoutComponent } from "../../ui/layout/index.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { HorizontalTab } from "../../ui/organisms/horizontalTab/index.tsx";
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts";
import { Tab } from "../../ui/organisms/tab/index.tsx";
import { findAllReferrers } from "../company/referrers/slice.ts";
import { findAllCompany } from "../company/slice.ts";
import { findAllCollaborator } from "../financialAdvisor/collaborator/slice.ts";
import { findAllFinancialAdvisor } from "../financialAdvisor/slice.ts";
import { findAllOperator } from "../operator/slice.ts";
import { DraftList } from "./draftList.tsx";
import { NoticeStatus } from "./dto.ts";
import { ListSent } from "./listSent.tsx";
import { NewOrEditNotice } from "./newOrEditNotice.tsx";
import { NewOrEditNoticeModal } from "./newOrEditNoticeModal.tsx";
import { ReceiverList } from "./receiverList.tsx";
import { setEditNoticeMessage, setEditNoticeProminent, setEditNoticeRequestStatus, setEditNoticeSubjectLine, setEditNoticeType, setEditNoticeUserIds, setNoticeFilterPage, setNoticeFilterReceiver, setNoticeFilterStatus, setOpenShowNoticeModal, setSaveOrEditNotice, setSelectedTab } from "./slice.ts";

export function Notice() {
    const dispatch = useAppDispatch()
    const noticeState = useAppSelector(state => state.notice)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const collaboratorState = useAppSelector(state => state.collaborator)
    const companyState = useAppSelector(state => state.company)
    const referrerState = useAppSelector(state => state.referrers)
    const operatorState = useAppSelector(state => state.operator)
    const layoutState = useAppSelector(state => state.layout)
    const authState = useAppSelector(state => state.auth)

    useEffect(() => {
        if (!keycloak.hasRealmRole("financial_advisor") && !keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company"))
            dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
        if (!keycloak.hasRealmRole("financial_advisor_collaborator") && !keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company"))
            dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
        if (!keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company"))
            dispatch(findAllCompany(companyState.companyFilters))
        if (!keycloak.hasRealmRole("administrative_company"))
            dispatch(findAllReferrers(referrerState.referrersFilter))
        if (keycloak.hasRealmRole("admin"))
            dispatch(findAllOperator(operatorState.operatorFilters))
    }, [keycloak.hasRealmRole])

    useEffect(() => {
        if (keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator")) {
            dispatch(setSelectedTab(1))
        }
    }, [keycloak.hasRealmRole])

    const viewNoticeMap = new Map<number | undefined, ReactElement>([
        [0, <ReceiverList />],
        [1, <ListSent />],
        [2, <DraftList />],
        [undefined, <NewOrEditNotice />]
    ])

    return (
        <LayoutComponent
            breadcrumbItems={['Avvisi']}
            headingLabel={"Gestione Avvisi"}
            headingButtons={[
                noticeState.selectedTab === undefined ?
                    <ButtonComponent
                        label={"Torna agli Avvisi"}
                        onClick={() => {
                            dispatch(setEditNoticeUserIds([]))
                            dispatch(setEditNoticeMessage(""))
                            dispatch(setEditNoticeSubjectLine(""))
                            dispatch(setEditNoticeProminent(false))
                            dispatch(setEditNoticeType(""))
                            dispatch(setEditNoticeRequestStatus(""))
                            dispatch(setNoticeFilterStatus(NoticeStatus.SENT))
                            if (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('operator')) {
                                dispatch(setNoticeFilterReceiver(''))
                                dispatch(setSelectedTab(1))
                            } else {
                                dispatch(setSelectedTab(0))
                            }
                        }}
                        color={"gray"}
                        variant={"outline"}
                        size={"md"}
                        iconPosition={"left"}
                        icon={<ArrowLeftIcon size={"24"} color={colors.neutral[500]} />}
                    />
                    : !keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company") ?
                        <ButtonComponent
                            label="Nuovo Avviso"
                            size={"sm"}
                            iconPosition={"off"}
                            variant={"solid"}
                            color={"blue"}
                            onClick={() => {
                                dispatch(setSaveOrEditNotice('save'))
                                dispatch(setOpenShowNoticeModal(true))
                                // dispatch(setSelectedTab(undefined))
                            }}
                            disabled={
                                (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                                (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                            }
                        />
                        :
                        <></>
            ]}
            breadcrumbIcon={<NoticeIcon size="20" color={layoutState.theme[500]} />}
            menuItem={MenuItems.NOTICE}
        >
            <HorizontalTab>
                {
                    !keycloak.hasRealmRole("admin") && !keycloak.hasRealmRole("operator") &&
                    <Tab
                        label={"In entrata"}
                        selectedPrimary={noticeState.selectedTab === 0}
                        onClick={() => {
                            dispatch(setNoticeFilterReceiver('true'))
                            dispatch(setNoticeFilterPage(0))
                            dispatch(setNoticeFilterStatus(NoticeStatus.SENT))
                            dispatch(setSelectedTab(0))
                        }}
                        type="primary"
                    />
                }
                {
                    !keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company") &&
                    <>
                        <Tab
                            label={"Inviati"}
                            selectedPrimary={noticeState.selectedTab === 1}
                            onClick={() => {
                                if (!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('operator'))
                                    dispatch(setNoticeFilterReceiver('false'))
                                dispatch(setNoticeFilterPage(0))
                                dispatch(setNoticeFilterStatus(NoticeStatus.SENT))
                                dispatch(setSelectedTab(1))
                            }}
                            type="primary"
                        />
                        <Tab
                            label={"Bozze"}
                            selectedPrimary={noticeState.selectedTab === 2}
                            onClick={() => {
                                if (!keycloak.hasRealmRole('admin') && !keycloak.hasRealmRole('operator'))
                                    dispatch(setNoticeFilterReceiver('false'))
                                dispatch(setNoticeFilterPage(0))
                                dispatch(setNoticeFilterStatus(NoticeStatus.DRAFT))
                                dispatch(setSelectedTab(2))
                            }}
                            type="primary"
                        />
                    </>
                }
            </HorizontalTab>
            {viewNoticeMap.get(noticeState.selectedTab)}
            <NewOrEditNoticeModal />
        </LayoutComponent>
    )
}