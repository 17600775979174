import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { keycloak } from "../../../../lib/keycloak/index.ts";
import { NewObjectService } from "../../../../lib/object/service.ts";
import { findFileById, setFindDocumentStatus } from "../../../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hook.ts";
import { monthMap } from "../../../../lib/utilis/index.ts";
import { colors } from "../../../../ui/colors.ts";
import { DownloadIcon } from "../../../../ui/icons/downloadIcon.tsx";
import { ReceivedIcon } from "../../../../ui/icons/receivedIcon.tsx";
import { TrashIcon } from "../../../../ui/icons/trashIcon.tsx";
import { Pills } from "../../../../ui/molecules/pills/index.tsx";
import { SpinnerComponent } from "../../../../ui/molecules/spinner/index.tsx";
import { ConfirmModal } from "../../../../ui/organisms/confirmModal/index.tsx";
import { Dropdown } from "../../../../ui/organisms/dropDown/index.tsx";
import { InfoTextPopup } from "../../../../ui/organisms/infoTextPopup/index.tsx";
import { CompanyStatus } from "../../dto.ts";
import { DocumentType, DocumentTypologyMap } from "../dto.ts";
import { deleteDocument, findAllReceivedDocuments, findByIdDocument, setDeleteConfirmOrDenyModal } from "../slice.ts";


export function DocumentReceived() {
    const dispatch = useAppDispatch()
    const documentState = useAppSelector(state => state.document)
    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)

    const objectService = NewObjectService()

    const [isDelete, setIsDelete] = useState<string | undefined>()

    useEffect(() => {
        dispatch(findAllReceivedDocuments({
            companyId: documentState.documentFilters.companyId,
            itemsPerPage: documentState.documentFilters.itemsPerPage,
            order: documentState.documentFilters.order,
            page: documentState.documentFilters.page,
            receiverGroup: documentState.documentFilters.receiverGroup,
            referenceYear: documentState.documentFilters.referenceYear,
            referenceMonth: documentState.documentFilters.referenceMonth,
            senderGroup: "",
            sort: documentState.documentFilters.sort,
            type: documentState.documentFilters.type,
            typology: documentState.documentFilters.typology,
            fileName: documentState.documentFilters.fileName,
            status: documentState.documentFilters.status
        }))
    }, [])

    useEffect(() => {
        dispatch(findAllReceivedDocuments({
            companyId: documentState.documentFilters.companyId,
            itemsPerPage: documentState.documentFilters.itemsPerPage,
            order: documentState.documentFilters.order,
            page: documentState.documentFilters.page,
            receiverGroup: documentState.documentFilters.receiverGroup,
            referenceYear: documentState.documentFilters.referenceYear,
            referenceMonth: documentState.documentFilters.referenceMonth,
            senderGroup: "",
            sort: documentState.documentFilters.sort,
            type: documentState.documentFilters.type,
            typology: documentState.documentFilters.typology,
            fileName: documentState.documentFilters.fileName,
            status: documentState.documentFilters.status
        }))
    }, [documentState.documentFilters.page])

    useEffect(() => {
        if (documentState.saveDocumentStatus === "successfully") {
            dispatch(findAllReceivedDocuments({
                companyId: documentState.documentFilters.companyId,
                itemsPerPage: documentState.documentFilters.itemsPerPage,
                order: documentState.documentFilters.order,
                page: documentState.documentFilters.page,
                receiverGroup: documentState.documentFilters.receiverGroup,
                referenceYear: documentState.documentFilters.referenceYear,
                referenceMonth: documentState.documentFilters.referenceMonth,
                senderGroup: "",
                sort: documentState.documentFilters.sort,
                type: documentState.documentFilters.type,
                typology: documentState.documentFilters.typology,
                fileName: documentState.documentFilters.fileName,
                status: documentState.documentFilters.status
            }))
        }
    }, [documentState.saveDocumentStatus])

    useEffect(() => {
        if (documentState.deleteDocumentStatus === "successfully") {
            dispatch(findAllReceivedDocuments({
                companyId: documentState.documentFilters.companyId,
                itemsPerPage: documentState.documentFilters.itemsPerPage,
                order: documentState.documentFilters.order,
                page: documentState.documentFilters.page,
                receiverGroup: documentState.documentFilters.receiverGroup,
                referenceYear: documentState.documentFilters.referenceYear,
                referenceMonth: documentState.documentFilters.referenceMonth,
                senderGroup: "",
                sort: documentState.documentFilters.sort,
                type: documentState.documentFilters.type,
                typology: documentState.documentFilters.typology,
                fileName: documentState.documentFilters.fileName,
                status: documentState.documentFilters.status
            }))
        }
    }, [documentState.deleteDocumentStatus])

    if (documentState.findAllReceivedDocumentsStatus === "loading" ||
        documentState.saveDocumentStatus === "loading" ||
        documentState.deleteDocumentStatus === "loading") {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <div className="w-full flex flex-col gap-4 rounded-tr-xl rounded-tl-xl overflow-auto">
            <div>
                <table className="w-full mb-3">
                    <thead className="h-[56px] bg-neutral-100 font-semibold text-neutral-500 text-text-xs">
                        <tr>
                            <th className="text-left">
                                <div className="flex py-4 px-4 gap-1">
                                    NOME DEL FILE
                                </div>
                            </th>
                            {
                                documentState.findAllReceivedDocumentsResponse?.total !== 0 &&
                                <>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            TIPOLOGIA
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            STATO
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            MESE
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            ANNO
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            DATA INVIO
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            ULTIMO DOWNLOAD
                                        </div>
                                    </th>
                                    <th className="text-left">
                                    </th>
                                </>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            documentState.findAllReceivedDocumentsResponse?.data.filter(item => item.type === DocumentType.accounting).length === 0 ?
                                <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 w-full">
                                    <td className="py-4 px-4 text-text-sm font-medium text-neutral-500">
                                        Nessun documento trovato!
                                    </td>
                                </tr>
                                :
                                documentState.findAllReceivedDocumentsResponse?.data?.filter(item => item.type === DocumentType.accounting).map(item =>
                                    <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 text-text-sm font-semibold text-neutral-600">
                                        <td className="py-4 px-4 text-neutral-800">{item.fileName.length <= 25 ?
                                            item.fileName : item.fileName.slice(0, 25).concat('...')}
                                        </td>
                                        <td className="py-4 px-2"><Pills size={"xs"} label={DocumentTypologyMap.get(item.typology)!} outline={true} emphasis={false} color={"gray"} /></td>
                                        <td className="py-4 px-2">
                                            <Pills
                                                size={"xs"}
                                                label={"Ricevuto"}
                                                outline={false}
                                                emphasis={true}
                                                color={"green"}
                                                endIcon={<ReceivedIcon size={"12"} color={""} />}
                                            />
                                        </td>
                                        <td className="py-4 px-2">{monthMap.get(item.referenceMonth)}</td>
                                        <td className="py-4 px-2">{item.referenceYear}</td>
                                        <td className="py-4 px-2">{item.date && format(new Date(item.date), 'dd/MM/yyyy')}</td>
                                        <td className="py-4 px-2">
                                            {
                                                item.operatorsDownloadDate !== null ?
                                                    format(new Date(item.operatorsDownloadDate), 'dd/MM/yyyy')
                                                    :
                                                    item.companiesDownloadDate !== null ? format(new Date(item.companiesDownloadDate), 'dd/MM/yyyy')
                                                        :
                                                        '-'
                                            }
                                        </td>
                                        <td className="h-[56px] py-4 px-2 flex flex-row justify-end items-center">
                                            <div className="hover:bg-brandPrimary-50 py-2 px-2 rounded-md">
                                                <InfoTextPopup
                                                    label={"Scarica"}
                                                    children={
                                                        <DownloadIcon
                                                            size={"24"}
                                                            color={colors.brandSecondary[500]}
                                                            onClick={() => {
                                                                dispatch(findFileById(item.objectId.toString())).then((e) => {
                                                                    //@ts-ignore
                                                                    objectService.downloadBase64WithExtension(item.fileName, e.payload !== null ? e.payload : '')
                                                                    dispatch(findByIdDocument(item.id.toString())).then(() => {
                                                                        dispatch(setFindDocumentStatus('idle'))
                                                                        dispatch(findAllReceivedDocuments({
                                                                            companyId: documentState.documentFilters.companyId,
                                                                            itemsPerPage: documentState.documentFilters.itemsPerPage,
                                                                            order: documentState.documentFilters.order,
                                                                            page: documentState.documentFilters.page,
                                                                            receiverGroup: documentState.documentFilters.receiverGroup,
                                                                            referenceYear: documentState.documentFilters.referenceYear,
                                                                            referenceMonth: documentState.documentFilters.referenceMonth,
                                                                            senderGroup: "",
                                                                            sort: documentState.documentFilters.sort,
                                                                            type: documentState.documentFilters.type,
                                                                            typology: documentState.documentFilters.typology,
                                                                            fileName: documentState.documentFilters.fileName,
                                                                            status: documentState.documentFilters.status
                                                                        }))
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                    }
                                                    position={"bottom"}
                                                />
                                            </div>
                                            {
                                                (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                                (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                                                (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                                (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) ||
                                                (keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator")) &&
                                                <Dropdown items={[
                                                    {
                                                        label: 'Scarica',
                                                        icon: <DownloadIcon size="24" color="" />,
                                                        onClick: () => {
                                                            dispatch(findFileById(item.objectId.toString())).then((e) => {
                                                                //@ts-ignore
                                                                objectService.downloadBase64WithExtension(item.fileName, e.payload !== null ? e.payload : '')
                                                                dispatch(findByIdDocument(item.id.toString())).then(() => {
                                                                    dispatch(setFindDocumentStatus('idle'))
                                                                    dispatch(findAllReceivedDocuments({
                                                                        companyId: documentState.documentFilters.companyId,
                                                                        itemsPerPage: documentState.documentFilters.itemsPerPage,
                                                                        order: documentState.documentFilters.order,
                                                                        page: documentState.documentFilters.page,
                                                                        receiverGroup: documentState.documentFilters.receiverGroup,
                                                                        referenceYear: documentState.documentFilters.referenceYear,
                                                                        referenceMonth: documentState.documentFilters.referenceMonth,
                                                                        senderGroup: "",
                                                                        sort: documentState.documentFilters.sort,
                                                                        type: documentState.documentFilters.type,
                                                                        typology: documentState.documentFilters.typology,
                                                                        fileName: documentState.documentFilters.fileName,
                                                                        status: documentState.documentFilters.status
                                                                    }))
                                                                })
                                                            })
                                                        }
                                                    },
                                                    {
                                                        label: 'Cancella',
                                                        icon: <TrashIcon color="" size={""} />,
                                                        onClick: () => {
                                                            setIsDelete(item.id.toString())
                                                            dispatch(setDeleteConfirmOrDenyModal(true))
                                                        },
                                                        visible: companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED
                                                    }
                                                ]} />
                                            }
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            </div>
            <ConfirmModal
                open={documentState.deleteConfirmOrDenyModal}
                handleClose={() => dispatch(setDeleteConfirmOrDenyModal(false))}
                title={"Elimina documento"}
                description={"Sei sicuro di voler eliminare questo documento?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    isDelete && dispatch(deleteDocument(isDelete))
                    dispatch(setDeleteConfirmOrDenyModal(false))
                }}
            />
        </div>
    )
}