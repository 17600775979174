import React, { ReactElement } from 'react';
import { useAppSelector } from '../../../lib/redux/hook.ts';
import './avatar.css';
import { AvatarGroupLength, AvatarGroupSize, AvatarInfoSize, AvatarProfileIcon, AvatarProfileShape, AvatarProps, AvatarSingleShape, AvatarSize, profileShapeMap, singleSizeMap, singleSquareRadiusMap } from './avatar.types.ts';

const Avatar: React.FC<AvatarProps> = (props) => {
    switch (props.type) {
        case 'single':
            return <AvatarSingle size={props.size} shape={props.shape} imageUrl={props.imageUrl} altTextInitials={props.altTextInitials} />;
        case 'profile':
            return <AvatarProfile shape={props.shape} imageUrl={props.imageUrl} profileIcon={props.profileIcon} profileInfo={props.profileInfo} placeholder={props.placeholder} altTextInitials={props.altTextInitials} />;
        case 'info':
            return <AvatarInfo size={props.size} imageUrl={props.imageUrl} firstRow={props.firstRow} secondRow={props.secondRow} thirdRow={props.thirdRow} altTextInitials={props.altTextInitials} />;
        case 'group':
            return <AvatarGroup size={props.size} imageUrls={props.imageUrls} length={props.length} />;
        default:
            return null;
    }
};

const AvatarProfile: React.FC<{
    shape: AvatarProfileShape,
    imageUrl: string,
    profileIcon?: AvatarProfileIcon,
    profileInfo?: string,
    placeholder: string,
    altTextInitials: string
}> = ({ shape,
    imageUrl,
    profileIcon,
    profileInfo,
    placeholder,
    altTextInitials }) => {

        const layoutState = useAppSelector(state => state.layout)

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '160px' }}>
                {
                    imageUrl === '' || !imageUrl ?
                        <div
                            className='flex items-center object-cover justify-center text-text-md font-medium'
                            style={{ ...profileShapeMap.get(shape), color: layoutState.theme[500], backgroundColor: layoutState.theme[200] }}
                        >
                            {altTextInitials}
                        </div> :
                        <img
                            className='object-cover'
                            src={imageUrl}
                            style={{ ...profileShapeMap.get(shape) }}
                            alt="ProfileAvatar"
                        />
                }
                <div style={{ width: '160px' }}>
                    <span className='avatar-profile-placeholder'>{placeholder}</span>
                    {
                        profileInfo && (
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '4px' }}>
                                {
                                    profileIcon &&
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {
                                            profileIcon === 'email' ? (
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.6667 4.00173C14.6667 3.2684 14.0667 2.6684 13.3333 2.6684H2.66668C1.93334 2.6684 1.33334 3.2684 1.33334 4.00173M14.6667 4.00173V12.0017C14.6667 12.7351 14.0667 13.3351 13.3333 13.3351H2.66668C1.93334 13.3351 1.33334 12.7351 1.33334 12.0017V4.00173M14.6667 4.00173L8.00001 8.6684L1.33334 4.00173" stroke="#ADADD6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            ) : (
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 8.66841V12.6684C12 13.022 11.8595 13.3612 11.6095 13.6112C11.3594 13.8613 11.0203 14.0017 10.6667 14.0017H3.33333C2.97971 14.0017 2.64057 13.8613 2.39052 13.6112C2.14048 13.3612 2 13.022 2 12.6684V5.33507C2 4.98145 2.14048 4.64231 2.39052 4.39226C2.64057 4.14222 2.97971 4.00174 3.33333 4.00174H7.33333M10 2.00174H14M14 2.00174V6.00174M14 2.00174L6.66667 9.33507" stroke="#ADADD6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            )
                                        }
                                    </div>
                                }
                                <span className='avatar-profile-info'>{profileInfo}</span>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

const AvatarSingle: React.FC<{ size: AvatarSize, shape: AvatarSingleShape, imageUrl: string, altTextInitials: string }> = ({ size, shape, imageUrl, altTextInitials }) => {
    const layoutState = useAppSelector(state => state.layout)

    const radius = shape === 'square' ? singleSquareRadiusMap.get(size) : { borderRadius: '50%' }

    return (
        <div>
            {
                imageUrl === '' || !imageUrl ?
                    <div
                        className=' flex items-center justify-center text-text-md font-medium'
                        style={{ ...singleSizeMap.get(size), ...radius, color: layoutState.theme[500], backgroundColor: layoutState.theme[200] }}
                    >
                        {altTextInitials}
                    </div> :
                    <img
                        src={imageUrl}
                        className='object-cover'
                        style={{ ...singleSizeMap.get(size), ...radius }}
                        alt="SingleAvatar"
                    />
            }
        </div>
    )
}

const AvatarInfo: React.FC<{ size: AvatarInfoSize, imageUrl: string, firstRow: string, secondRow?: string, thirdRow?: string, altTextInitials: string }> = ({ size, imageUrl, firstRow, secondRow, thirdRow, altTextInitials }) => {
    const radius = { borderRadius: '50%' }

    const layoutState = useAppSelector(state => state.layout)

    return (
        <div style={{
            display: 'flex', gap: '8px', alignItems: secondRow === undefined ? 'center' : ''
            // height: size === 'xs' ? '58px' : size === 'sm' ? '64px' : size === 'md' ? '66px' : size === 'lg' ? '68px' : size === 'xl' ? '70px' : '68px'
        }}>
            {
                imageUrl === '' || !imageUrl ?
                    <div
                        className='flex p-3 items-center justify-center text-text-md font-medium'
                        style={{ ...singleSizeMap.get(size), ...radius, color: layoutState.theme[500], backgroundColor: layoutState.theme[200] }}
                    >
                        {String(altTextInitials)}
                    </div> :
                    <img
                        className='object-cover'
                        src={imageUrl}
                        style={{ ...singleSizeMap.get(size), ...radius }}
                        alt="SingleAvatar"
                    />
            }
            <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <span className={'avatar-info-first-row-' + size}>{firstRow}</span>
                {
                    (secondRow || thirdRow) &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {secondRow && <span className={'avatar-info-second-row-' + size}>{secondRow} </span>}
                        {thirdRow && <span className={'avatar-info-second-row-' + size} style={{ marginTop: '-2px' }}>{thirdRow}</span>}
                    </div>
                }
            </div>
        </div>
    )
}

const AvatarGroup: React.FC<{ size: AvatarGroupSize, imageUrls: { url: string, altTextInitials: string }[], length: AvatarGroupLength }> = ({ size, imageUrls, length }) => {
    let avatars: ReactElement[] = []
    const layoutState = useAppSelector(state => state.layout)

    avatars = imageUrls.map((url, index) => (
        <>
            {
                url.url === '' || !url.url ?
                    <div
                        className=' flex items-center justify-center text-text-md font-medium '
                        style={{ ...singleSizeMap.get(size), ...{ borderRadius: '50%', border: '2px solid white', marginLeft: index !== 0 ? '-4px' : '0px', color: layoutState.theme[500], backgroundColor: layoutState.theme[200] } }}
                    >
                        {url.altTextInitials}
                    </div> :
                    <img
                        className='object-cover'
                        src={url.url}
                        style={{ ...singleSizeMap.get(size), ...{ borderRadius: '50%', border: '2px solid white', marginLeft: index !== 0 ? '-4px' : '0px' } }}
                        alt="SingleAvatar"
                    />
            }
        </>
    ))

    return (
        <div style={{ display: 'flex' }}>
            {
                avatars.length < length ?
                    avatars :
                    avatars.slice(0, length).concat(
                        <div
                            style={{
                                ...singleSizeMap.get(size), ...{
                                    borderRadius: '50%',
                                    border: '2px solid white',
                                    marginLeft: '-4px',
                                    backgroundColor: 'rgba(241, 242, 253, 1)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }
                            }}
                        >
                            <span className={'avatar-group-label-' + size}>
                                {'+ ' + (avatars.length - length > 9 ? 9 : (avatars.length - length))}
                            </span>
                        </div>
                    )
            }
        </div>
    )
};

export default Avatar;
