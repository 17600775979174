import React, { useEffect, useState } from "react";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { findFileById, setFindDocumentStatus } from "../../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { PromiseStatuses, toBase64 } from "../../../lib/utilis/index.ts";
import { colors } from "../../../ui/colors.ts";
import { EmailIcon } from "../../../ui/icons/emailIcon.tsx";
import Avatar from "../../../ui/molecules/avatar/index.tsx";
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx";
import { FileInputComponent } from "../../../ui/molecules/fileInput/index.tsx";
import { InputComponent } from "../../../ui/molecules/input/index.tsx";
import { SpinnerComponent } from "../../../ui/molecules/spinner/index.tsx";
import { ModalComponent } from "../../../ui/organisms/modal/index.tsx";
import { CompanyStatus } from "../dto.ts";
import { createReferrer, editReferrer, resetCreateReferrersRequest, setCreateReferrersClientId, setCreateReferrersEmail, setCreateReferrersName, setCreateReferrersNote, setCreateReferrersSurname, setEditReferrersEmail, setEditReferrersName, setEditReferrersNote, setEditReferrersSurname, setFindByIdReferrerStatus, setNewOrEditReferrersModal } from "./slice.ts";

export function NewOrEditReferrersModal() {
    const dispatch = useAppDispatch()
    const referrersState = useAppSelector(state => state.referrers)
    const companyState = useAppSelector(state => state.company)
    const objectState = useAppSelector(state => state.object)
    const authState = useAppSelector(state => state.auth)

    const [loading, setLoading] = useState<PromiseStatuses>("idle")
    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    let formData = new FormData();

    useEffect(() => {
        if (referrersState.openNewOrEditReferrersModal) {
            setFile(null)
            setAvatarToBase64('')
        }
    }, [referrersState.openNewOrEditReferrersModal])

    useEffect(() => {
        if (referrersState.openNewOrEditReferrersModal && referrersState.saveOrEditModal === "edit")
            setLoading("loading")
    }, [referrersState.openNewOrEditReferrersModal, referrersState.saveOrEditModal])

    useEffect(() => {
        if (companyState.findByIdCompanyStatus === "successfully" && referrersState.openNewOrEditReferrersModal) {
            dispatch(setCreateReferrersClientId(companyState.findByIdCompanyResponse?.id))
        }
    }, [companyState.findByIdCompanyStatus, referrersState.openNewOrEditReferrersModal])

    useEffect(() => {
        if (referrersState.findByIdReferrerStatus === "successfully" && referrersState.saveOrEditModal === 'edit') {
            dispatch(findFileById(referrersState.findByIdReferrerResponse?.avatarObjectId!))
            dispatch(setEditReferrersName(referrersState.findByIdReferrerResponse?.name))
            dispatch(setEditReferrersSurname(referrersState.findByIdReferrerResponse?.surname))
            dispatch(setEditReferrersEmail(referrersState.findByIdReferrerResponse?.email))
            dispatch(setEditReferrersNote(referrersState.findByIdReferrerResponse?.note))
            setLoading("successfully")
        }
    }, [referrersState.findByIdReferrerStatus, referrersState.saveOrEditModal])

    useEffect(() => {
        if (objectState.findDocumentStatus === "successfully" && referrersState.saveOrEditModal === 'edit') {
            setAvatarToBase64(objectState.document!)
            dispatch(setFindDocumentStatus('idle'))
        }
    }, [objectState.findDocumentStatus])

    return (
        <ModalComponent
            width="medium"
            open={referrersState.openNewOrEditReferrersModal}
            handleClose={() => {
                dispatch(setNewOrEditReferrersModal(false));
            }}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: '0px 12px 24px 0px rgba(22, 42, 100, 0.12)',
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    width: '100%'
                }}
            >
                {
                    referrersState.findByIdReferrerStatus === "loading" || loading === "loading" || objectState.findDocumentStatus === "loading" ?
                        <div className="flex justify-center items-center">
                            <SpinnerComponent />
                        </div>
                        :
                        <>
                            <div className="flex items-center justify-between">
                                <span className="text-heading-xs font-semibold text-neutral-600">{referrersState.saveOrEditModal === 'save' ? "Aggiungi referente" : "Modifica referente"}</span>
                            </div>
                            <div className="flex flex-col items-start" style={{ gap: '16px', width: '100%' }}>
                                <div className="flex gap-[16px] flex-row w-full">
                                    <Avatar
                                        type="single"
                                        size="md"
                                        shape="circle"
                                        imageUrl={avatarToBase64}
                                        altTextInitials={referrersState.saveOrEditModal === 'save' ?
                                            (referrersState.createReferrersRequest.name[0] || 'A') + (referrersState.createReferrersRequest.surname[0] || 'A')
                                            :
                                            (referrersState.editReferrersRequest.name[0] || 'A') + (referrersState.editReferrersRequest.surname[0] || 'A')
                                        }
                                    />
                                    <FileInputComponent
                                        id={"create-collaborator-avatar"}
                                        onChange={(e) => {
                                            if (e.length) {
                                                setFile(e[0])
                                                toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data))
                                            }
                                        }}
                                        color={"gray"}
                                        iconSize="32"
                                        iconBackground={colors.neutral[100]}
                                        infoText={false}
                                        disabled={companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED || (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                            (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))}
                                    />
                                </div>
                                <div className=" flex flex-row gap-4">
                                    <InputComponent
                                        label="Nome"
                                        type={"text"}
                                        defaultValue={referrersState.saveOrEditModal === 'save' ? referrersState.createReferrersRequest.name : referrersState.editReferrersRequest.name}
                                        placeholder={"Nome"}
                                        onChangeText={(value) => {
                                            if (referrersState.saveOrEditModal === 'save')
                                                dispatch(setCreateReferrersName(value))
                                            else
                                                dispatch(setEditReferrersName(value))
                                        }}
                                        required
                                        disabled={keycloak.hasRealmRole("financial_advisor") ||
                                            keycloak.hasRealmRole("financial_advisor_collaborator") ||
                                            keycloak.hasRealmRole("administrative_company") ||
                                            companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                            (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                            (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                                        }
                                    />
                                    <InputComponent
                                        label="Cognome"
                                        type={"text"}
                                        defaultValue={referrersState.saveOrEditModal === 'save' ? referrersState.createReferrersRequest.surname : referrersState.editReferrersRequest.surname}
                                        placeholder={"Cognome"}
                                        onChangeText={(value) => {
                                            if (referrersState.saveOrEditModal === 'save')
                                                dispatch(setCreateReferrersSurname(value))
                                            else
                                                dispatch(setEditReferrersSurname(value))
                                        }}
                                        required
                                        disabled={keycloak.hasRealmRole("financial_advisor") ||
                                            keycloak.hasRealmRole("financial_advisor_collaborator") ||
                                            keycloak.hasRealmRole("administrative_company") ||
                                            companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                            (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                            (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                                        }
                                    />
                                </div>
                                <InputComponent
                                    label="Email"
                                    type={"text"}
                                    placeholder="Email"
                                    startIcon={<EmailIcon size={"20"} color={colors.neutral[400]} />}
                                    defaultValue={referrersState.saveOrEditModal === 'save' ? referrersState.createReferrersRequest.email : referrersState.editReferrersRequest.email}
                                    onChangeText={(value) => {
                                        dispatch(setCreateReferrersEmail(value))
                                    }}
                                    required
                                    disabled={referrersState.saveOrEditModal === 'edit' ||
                                        keycloak.hasRealmRole("financial_advisor") ||
                                        keycloak.hasRealmRole("financial_advisor_collaborator") ||
                                        keycloak.hasRealmRole("administrative_company") ||
                                        companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                        (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                        (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                                    }
                                />
                                <InputComponent
                                    type={"text"}
                                    label="Descrizione"
                                    placeholder="Un po' di informazioni su di te"
                                    supportingText={275 - referrersState.createReferrersRequest.note.length + " caratteri rimasti"}
                                    multiple
                                    onChangeText={(value) => {
                                        if (referrersState.saveOrEditModal === 'save')
                                            dispatch(setCreateReferrersNote(value))
                                        else
                                            dispatch(setEditReferrersNote(value))
                                    }}
                                    defaultValue={referrersState.saveOrEditModal === 'save' ? referrersState.createReferrersRequest.note : referrersState.editReferrersRequest.note}
                                    disabled={keycloak.hasRealmRole("financial_advisor") ||
                                        keycloak.hasRealmRole("financial_advisor_collaborator") ||
                                        keycloak.hasRealmRole("administrative_company") ||
                                        companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                        (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                        (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                                    }
                                />
                            </div>
                            <div className={"flex gap-3 items-center"}>
                                <ButtonComponent
                                    label={"Annulla"}
                                    onClick={() => {
                                        dispatch(setNewOrEditReferrersModal(false))
                                        if (referrersState.saveOrEditModal === "save" || (!keycloak.hasRealmRole("financial_advisor") && !keycloak.hasRealmRole("financial_advisor_collaborator"))) {
                                            dispatch(resetCreateReferrersRequest())
                                        } else if (referrersState.saveOrEditModal === "edit" && (!keycloak.hasRealmRole("financial_advisor") || !keycloak.hasRealmRole("financial_advisor_collaborator"))) {
                                            dispatch(setEditReferrersName(""))
                                            dispatch(setEditReferrersSurname(""))
                                            dispatch(setFindByIdReferrerStatus('idle'))
                                            setLoading("idle")
                                        }
                                    }}
                                    color={"gray"}
                                    variant={"outline"}
                                    size={"sm"}
                                    iconPosition="off"
                                    fullWidth
                                    disabled={keycloak.hasRealmRole("financial_advisor") && keycloak.hasRealmRole("financial_advisor_collaborator") && keycloak.hasRealmRole("administrative_company") || companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED || (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                        (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))}
                                />
                                <ButtonComponent
                                    label={"Aggiungi"}
                                    onClick={() => {
                                        if (referrersState.saveOrEditModal === "save") {
                                            if (file !== null) {
                                                formData.append('avatar', file)
                                            }
                                            formData.append('administrativeCompanyCreationDTO', JSON.stringify(referrersState.createReferrersRequest))
                                            dispatch(createReferrer(formData))
                                            dispatch(setNewOrEditReferrersModal(false))
                                        } else {
                                            if (file !== null) {
                                                formData.append('avatar', file)
                                            }
                                            formData.append('user', JSON.stringify(referrersState.editReferrersRequest))
                                            dispatch(editReferrer({ id: referrersState.findByIdReferrerResponse?.id !== undefined ? referrersState.findByIdReferrerResponse?.id : "", request: formData }))
                                            dispatch(setNewOrEditReferrersModal(false))
                                        }
                                    }}
                                    color={"blue"}
                                    variant={"solid"}
                                    size={"sm"}
                                    iconPosition="off"
                                    fullWidth
                                    disabled={
                                        referrersState.saveOrEditModal === "save" &&
                                        (referrersState.createReferrersRequest.name === "" ||
                                            referrersState.createReferrersRequest.surname === "" ||
                                            referrersState.createReferrersRequest.email === "" ||
                                            (referrersState.createReferrersRequest.companyId === 0 && !keycloak.hasRealmRole("company"))
                                        ) ||
                                        referrersState.saveOrEditModal === "edit" && (
                                            referrersState.editReferrersRequest.name === "" ||
                                            referrersState.editReferrersRequest.surname === ""
                                        ) ||
                                        keycloak.hasRealmRole("financial_advisor") ||
                                        keycloak.hasRealmRole("financial_advisor_collaborator") ||
                                        keycloak.hasRealmRole("administrative_company") || companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                        (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                        (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                                    }
                                />
                            </div>
                        </>
                }
            </div>
        </ModalComponent >
    )
}